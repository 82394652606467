import React, { useState } from 'react';
import { FlexColumns, Select, SidepanelHeader } from '@alphakits/ui/dist';
import { AdminProfileRO } from '@escapenavigator/types/dist/profile/admin-profile.ro';
import { ModalContainer } from 'src/components/modal-container';
import { EmailSendForm } from 'src/modals/email-send-form';

type Props = {
    profile: AdminProfileRO;
    cb: () => void;
};

export const OrganizationEmail: React.FC<Props> = ({ profile, cb }) => {
    const [selectedEmail, setSelectedEmail] = useState(profile.mainEmail);

    return (
        <React.Fragment>
            <SidepanelHeader title="New Email" />
            <ModalContainer>
                <FlexColumns columns={ 1 } gr={ 20 }>
                    <Select
                        selected={ selectedEmail }
                        label="To"
                        onChange={ ({ selected }) => setSelectedEmail(selected.key as string) }
                        options={ [profile.mainEmail, ...profile.secondaryEmails].map((e) => ({
                            key: e,
                            content: e,
                        })) }
                        block={ true }
                    />

                    <EmailSendForm cb={ cb } email={ selectedEmail } />
                </FlexColumns>
            </ModalContainer>
        </React.Fragment>
    );
};
