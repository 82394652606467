import React, { useContext } from 'react';
import {
    Button, CopyButton, Flex, ModalContext,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';
import { NavigatorReviewModal } from 'src/modals/navigator-review';

const CreateReview = ({ questroom }: { questroom: QuestroomAdminRO }) => {
    const { openModal } = useContext(ModalContext);

    const onReviewClick = ({ id, title, navigatorReviewId }: QuestroomAdminRO) => {
        openModal(
            NavigatorReviewModal,
            'm',
        )(
            navigatorReviewId
                ? {
                    recordId: navigatorReviewId,
                    cb: () => {},
                }
                : {
                    questroomId: id,
                    questroomTitle: title,
                    cb: () => {},
                },
        );
    };

    return (
        <Button
            onClick={ (e) => {
                e.stopPropagation();

                return onReviewClick(questroom);
            } }
            size="xs"
            view={ questroom.navigatorReviewId ? 'outlined' : 'primary' }
        >
            Review
        </Button>
    );
};

export const columns = (): TableColumns<QuestroomAdminRO> => [
    {
        header: 'Title',
        accessor: 'title',
        sort: true,
        row: {
            image: 'photo',
            subtitle: 'slug',
        },
    },
    {
        header: 'ID',
        row: {
            title: ({ row }) =>
                (<CopyButton fullText={ `${row.id}` } text={ `${row.id}` } />) as any as string,
        },
    },
    {
        header: 'Verified',
        accessor: 'verified',
        sort: true,
        row: {
            title: ({ row }) => <React.Fragment>{ row.verified ? 'Yes' : 'No' }</React.Fragment>,
        },
    },
    {
        header: 'Teaser',
        accessor: 'teaser',
        sort: true,
    },
    {
        header: 'Profile',
        row: {
            title: ({ row }) => `${row.profile.title} (${row.profile.country})`,
            subtitle: ({ row }) =>
                (
                    <CopyButton fullText={ `${row.profile.id}` } text={ `${row.profile.id}` } />
                ) as any as string,
        },
    },
    {
        header: 'Options',
        accessor: 'navigatorReviewId',
        sort: true,
        row: {
            title: ({ row }) => (
                <Flex gap="md">
                    <CreateReview questroom={ row } />
                </Flex>
            ),
        },
    },
];
