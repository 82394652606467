import React from 'react';
import {
    AllertMarkM, GearM, InfoMarkM, PortfolioM,
} from '@alphakits/icons';
import { Typography } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';
import { format } from 'date-fns';

import { EmailTableButtons } from '../profile-button';

export const getEmailIcons = (email: EmailRO) => {
    const key = (email.status === EmailStatusEnum.INBOX ? email.to : email.from).split('@')[0];
    const icons = {
        info: <InfoMarkM />,
        sales: <PortfolioM />,
        support: <GearM />,
    };

    return icons[key] || <AllertMarkM />;
};

export const columns = (): TableColumns<EmailRO> => [
    {
        header: 'Name',
        accessor: 'from',
        row: {
            icon: ({ row }) => getEmailIcons(row),
            subtitle: ({ row }) => format(new Date(row.createdAt), 'dd MMMM yyyy, HH:mm'),
            title: ({ row }) => (
                <Typography.Text color={ row.replied ? 'primary' : 'attention' } view="title">
                    { row.from }
                </Typography.Text>
            ),
        },
    },
    {
        header: 'Subject',
        accessor: 'subject',
        row: {
            subtitle: ({ row }) => (row.text || row.html)?.slice(0, 100) || '',
        },
    },
    {
        header: 'Actions',
        accessor: 'replied',
        row: {
            title: ({ row }) => <EmailTableButtons email={ row } />,
        },
    },
];
