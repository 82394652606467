import React from 'react';
import {
    AmountInput, FlexColumns, Textarea, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateSocialReviewDto } from '@escapenavigator/types/dist/review/create-social-review.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    playerId: number;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const PlayerReviewModal = ({
    close, t, cb, playerId,
}: Props) => {
    const { socialReviews } = useApi();

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: socialReviews.createReview,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Update translation"
            close={ close }
            initialValues={ serializeRecord(CreateSocialReviewDto, {
                playerId,
            }) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 }>
                    <AmountInput
                        dataTestId="questroomId"
                        label="Questroom ID"
                        suffix=""
                        min="0"
                        error={ touched.questroomId && errors.questroomId }
                        value={ values.questroomId }
                        onChange={ (e, { value }) => setFieldValue('questroomId', value) }
                        block={ true }
                    />
                    <AmountInput
                        dataTestId="rating"
                        label="Rate"
                        suffix=""
                        min="0"
                        error={ touched.rating && errors.rating }
                        value={ values.rating }
                        onChange={ (e, { value }) => setFieldValue('rating', value) }
                        block={ true }
                    />
                    <Textarea
                        dataTestId="text"
                        label="Review"
                        value={ values.text }
                        maxLength={ 1500 }
                        counter={ true }
                        onChange={ handleChange('text') }
                        error={ touched.text && errors.text }
                        required={ true }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
