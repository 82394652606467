import React, { useEffect } from 'react';
import {
    convertToOptions,
    FlexColumns,
    Input,
    Select,
    Textarea,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { CreateTranslationDto } from '@escapenavigator/types/dist/translation/create-translation.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { FileUploader } from 'src/components/file-uploader';
import { Gallery } from 'src/components/gallery';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const TranslationModal = ({ close, t, cb }: Props) => {
    const { translationsApi } = useApi();

    const { getAllNamespacesData, getAllNamespacesFetch } = useApiMethod({
        api: translationsApi.getAllNamespaces,
    });

    useEffect(() => {
        getAllNamespacesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: translationsApi.createTranslation,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            loading={ !getAllNamespacesData }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Create translation"
            close={ close }
            initialValues={ serializeRecord(CreateTranslationDto, {}) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Select
                            dataTestId="namespaceId"
                            selected={ values.namespaceId }
                            label="Namespace"
                            onChange={ ({ selected }) => setFieldValue('namespaceId', selected?.key) }
                            options={ convertToOptions(getAllNamespacesData || []) }
                            error={ touched.namespaceId && errors.namespaceId }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            dataTestId="row"
                            label="Row"
                            value={ values.row }
                            onChange={ handleChange('row') }
                            error={ touched.row && errors.row }
                            required={ true }
                            block={ true }
                        />

                        <Textarea
                            label="Russian translation"
                            value={ values.text }
                            onChange={ handleChange('text') }
                            maxLength={ 1500 }
                            counter={ true }
                            error={ touched.text && errors.text }
                            required={ true }
                            block={ true }
                        />

                        <Textarea
                            label="Context"
                            value={ values.context }
                            maxLength={ 1500 }
                            counter={ true }
                            onChange={ handleChange('context') }
                            block={ true }
                        />

                        { values.photo && <Gallery src={ values.photo } /> }

                        <FileUploader setImage={ (img) => setFieldValue('photo', img) } />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
