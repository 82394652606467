import React from 'react';
import { Table } from '@alphakits/ui';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const Inbox: React.FC<DefaultPageProps> = () => {
    const { emails } = useApi();

    return (
        <Table.TableComponent
            tagsBuilder={ mapper() }
            columns={ columns() }
            searchPlaceholder="Serach by email"
            language="en"
            promise={ (data) =>
                emails.query({ ...data, where: { ...data.where, inbox: true, deleted: false } }) }
        />
    );
};
