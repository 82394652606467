import React from 'react';
import { Amount, Button } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { format } from 'date-fns';

export const data: Bill[] = [
    {
        amount: 13700,
        createdAt: new Date('2024-06-07'),
        canceledOrders: 3,
        aceptedOrders: 12,
        prifleTitle: 'The room',
        prifleId: 1,
    },
    {
        amount: 19400,
        createdAt: new Date('2024-06-06'),
        canceledOrders: 3,
        aceptedOrders: 17,
        prifleTitle: 'Escape game London',
        prifleId: 2,
    },
    {
        amount: 9100,
        createdAt: new Date('2024-06-01'),
        canceledOrders: 2,
        aceptedOrders: 7,
        prifleTitle: 'Escape Mania',
        prifleId: 4,
    },
];

type Bill = {
    amount: number;
    createdAt: Date;
    canceledOrders: number;
    aceptedOrders: number;
    prifleTitle: string;
    prifleId: number;
};

export const billsColumns: TableColumns<Bill> = [
    {
        header: 'Company',
        accessor: 'prifleId',
        sort: true,
        row: {
            title: ({ row }) => format(row.createdAt, 'dd MMMM yyyy'),
            subtitle: 'prifleTitle',
        },
    },
    {
        header: 'Payed',
        accessor: 'amount',
        sort: true,
        row: {
            title: ({ row }) => (
                <Amount
                    weight="bold"
                    color="positive"
                    type="decimal"
                    value={ row.amount }
                    currency={ ProfileCurrencyEnum.EUR }
                />
            ),
        },
    },
    {
        header: 'Accepted orders',
        accessor: 'aceptedOrders',
        sort: true,
    },
    {
        header: 'Canceled orders',
        accessor: 'canceledOrders',
        sort: true,
    },
    {
        header: '',
        accessor: 'canceledOrders',
        row: {
            title: () => (
                <Button view="outlined" size="xs">
                    Download
                </Button>
            ),
        },
    },
];
