import React, { useState } from 'react';
import { Divider, FlexColumns, Typography } from '@alphakits/ui';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileSubscriptionEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription.enum';
import { ProfileTagEnum } from '@escapenavigator/types/dist/profile/enum/profile-tag.enum';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';

import { CountriesFilter } from './countries-filter';
import { Row } from './row';

export const DashboardPage: React.FC = () => {
    const [country, setCountry] = useState<CountriesEnum>();
    const [city, setCity] = useState('');

    return (
        <div style={ { overflow: 'auto' } }>
            <FlexColumns columns={ 1 } gr={ 24 }>
                <CountriesFilter
                    setCity={ setCity }
                    city={ city }
                    country={ country }
                    setCountry={ setCountry }
                />
                <FlexColumns columns={ 1 } gr={ 32 }>
                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <Divider />

                        <Typography.Title tag="div" view="small" weight="bold" color="primary">
                            CRM
                        </Typography.Title>

                        <Row
                            title="Ready to call"
                            where={ {
                                notTags: [ProfileTagEnum.REJECT],
                                country,
                                city,
                                status: [ProfileStatusEnum.VERIFIED],
                                subscriptionType: ProfileSubscriptionTypeEnum.NONE,
                            } }
                            color="primary"
                        />

                        <Row
                            title="Trial"
                            where={ {
                                country,
                                city,
                                subscriptionStatus: [ProfileSubscriptionEnum.TRAILING],
                                subscriptionCancelationDate: false,
                            } }
                            color="primary"
                        />

                        <Row
                            title="Free CRM"
                            where={ {
                                country,
                                city,
                                subscriptionStatus: [ProfileSubscriptionEnum.ACTIVE],
                                subscriptionType: ProfileSubscriptionTypeEnum.FREE,
                                subscriptionCancelationDate: false,
                            } }
                            color="primary"
                        />
                        <Row
                            title="Pro CRM"
                            where={ {
                                country,
                                city,
                                subscriptionStatus: [ProfileSubscriptionEnum.ACTIVE],
                                subscriptionType: ProfileSubscriptionTypeEnum.PRO,
                                subscriptionCancelationDate: false,
                            } }
                            color="positive"
                        />
                        <Row
                            title="Unpaid bills"
                            where={ {
                                country,
                                city,
                                subscriptionCancelationDate: false,
                                subscriptionStatus: [
                                    ProfileSubscriptionEnum.PAST_DUE,
                                    ProfileSubscriptionEnum.INCOMPLITE,
                                    ProfileSubscriptionEnum.UNPAID,
                                ],
                            } }
                            color="attention"
                        />
                        <Row
                            title="Ended soon"
                            where={ {
                                country,
                                city,
                                subscriptionCancelationDate: true,
                            } }
                            color="negative"
                        />
                        <Row
                            title="End subscription"
                            where={ {
                                country,
                                city,
                                notTags: [ProfileTagEnum.REJECT],
                                subscriptionStatus: [
                                    ProfileSubscriptionEnum.CANCELED,
                                    ProfileSubscriptionEnum.INCOMPLITE_EXPIRED,
                                    ProfileSubscriptionEnum.PAUSED,
                                ],
                            } }
                            color="negative"
                        />

                        <Row
                            title="Rejected"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.VERIFIED],
                                tags: [ProfileTagEnum.REJECT],
                            } }
                            color="primary"
                        />
                    </FlexColumns>

                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <Divider />

                        <Typography.Title tag="div" view="small" weight="bold" color="primary">
                            Partner program
                        </Typography.Title>

                        <Row
                            title="Ready to call"
                            where={ {
                                notTags: [ProfileTagEnum.REJECT_INTEGRATION],
                                country,
                                city,
                                status: [ProfileStatusEnum.VERIFIED],
                                partnerProgram: PartnerProgramEnum.NONE,
                            } }
                            color="primary"
                        />

                        <Row
                            title="Requested"
                            where={ {
                                country,
                                city,
                                partnerProgram: PartnerProgramEnum.REQUESTED,
                            } }
                            color="primary"
                        />
                        <Row
                            title="Active"
                            where={ {
                                country,
                                city,
                                partnerProgram: PartnerProgramEnum.ACTIVE,
                            } }
                            color="primary"
                        />
                        <Row
                            title="Need setup"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.VERIFIED],
                                partnerProgram: PartnerProgramEnum.NEED_SETUP,
                            } }
                            color="negative"
                        />
                        <Row
                            title="Rejected"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.VERIFIED],
                                tags: [ProfileTagEnum.REJECT_INTEGRATION],
                            } }
                            color="negative"
                        />
                    </FlexColumns>

                    <Divider />

                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <Typography.Title tag="div" view="small" weight="bold" color="primary">
                            Verification
                        </Typography.Title>
                        <Row
                            title="Email sent"
                            where={ {
                                country,
                                city,
                                status: [
                                    ProfileStatusEnum.SENT_EMAIL,
                                    ProfileStatusEnum.OPEN_EMAIL,
                                    ProfileStatusEnum.OPEN_REQUEST_PAGE,
                                ],
                            } }
                            color="primary"
                        />
                        <Row
                            title="Verification"
                            where={ {
                                country,
                                city,
                                status: [
                                    ProfileStatusEnum.VERIFICATION_LOCATIONS,
                                    ProfileStatusEnum.VERIFICATION_PROFILE,
                                    ProfileStatusEnum.VERIFICATION_USER_DATA,
                                    ProfileStatusEnum.VERIFICATION_QUESTROOMS,
                                ],
                            } }
                            color="primary"
                        />

                        <Row
                            title="Not verified"
                            color="primary"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.NOT_VERIFIED],
                            } }
                        />
                        <Row
                            title="Ready to verify"
                            color="primary"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.READY_TO_VERIFY],
                            } }
                        />
                        <Row
                            title="Canceled"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.CANCELED],
                            } }
                            color="primary"
                        />

                        <Row
                            title="Closed"
                            where={ {
                                country,
                                city,
                                status: [ProfileStatusEnum.CLOSED],
                            } }
                            color="primary"
                        />
                    </FlexColumns>
                </FlexColumns>
            </FlexColumns>
        </div>
    );
};
