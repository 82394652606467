import React, { useState } from 'react';
import { StarFillS } from '@alphakits/icons';
import {
    Box, Button, Flex, Image, showError, Status, Tooltip, Typography,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { SocialReviewRO } from '@escapenavigator/types/dist/review/social-review.ro';
import { useApi } from 'src/providers/api/context';

const getColor = (rate: number) => {
    if (rate === 5) return 'positive';
    if (rate === 4) return 'accent';
    if (rate === 3) return 'attention';

    return 'negative';
};

const Tool = ({ text }: { text: string }) => {
    if (!text?.length || text.length <= 100) return <React.Fragment>{ text }</React.Fragment>;

    return (
        <Tooltip trigger="hover" position="bottom" content={ <Box width={ 400 }>{ text }</Box> }>
            <div>{ text.slice(0, 100) }</div>
        </Tooltip>
    );
};

const Accpet = ({ row }: { row: SocialReviewRO }) => {
    const { socialReviews } = useApi();
    const [approve, setApprove] = useState(row.accepted);

    const { unapproveFetch, unapproveLoading } = useApiMethod({
        api: socialReviews.unapprove,
        successCallback: ({ data }) => setApprove(data.accepted),
        errorCallback: ({ message }) => showError(message),
    });
    const { approveFetch, approveLoading } = useApiMethod({
        api: socialReviews.approve,
        successCallback: ({ data }) => setApprove(data.accepted),
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            onClick={ (e) => {
                e.stopPropagation();
                if (approve) return unapproveFetch(row.id);

                return approveFetch(row.id);
            } }
            view="ghost"
            loading={ unapproveLoading || approveLoading }
        >
            <Status view="contrast" color={ approve ? 'green' : 'grey' }>
                { approve ? 'Accepted' : 'Not accepted' }
            </Status>
        </Button>
    );
};

export const columns: TableColumns<SocialReviewRO> = [
    {
        header: 'Escape room',
        accessor: 'questroom',
        row: {
            title: ({ row }) => (
                <Typography.Text view="title" color={ getColor(row.rating) } weight="bold">
                    <Flex gap="xs" justify="start">
                        <StarFillS /> { row.rating }
                    </Flex>
                </Typography.Text>
            ),
            subtitle: ({ row }) => row.questroom.title,
            icon: ({ row }) => <Image view="ellipse" src={ row.questroom.photo } />,
        },
    },
    {
        header: 'Text',
        accessor: 'text',
        width: '30%',
        row: {
            title: ({ row }) => <Tool text={ row.text } />,
        },
    },
    {
        width: '30%',
        header: 'Response',
        accessor: 'response',
        row: {
            title: ({ row }) => <Tool text={ row.response } />,
        },
    },
    {
        header: 'City',
        accessor: 'cityId',
    },
    {
        header: 'Response',
        accessor: 'accepted',
        row: {
            title: ({ row }) => <Accpet row={ row } />,
        },
    },
];
