import React from 'react';
import { OpenS } from '@alphakits/icons';
import { Button } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { PlayerRO } from '@escapenavigator/types/dist/player/player.ro';

export const columns = (addReview): TableColumns<PlayerRO> => [
    {
        header: 'Name',
        accessor: 'fullName',
        row: {
            subtitle: 'email',
        },
    },
    {
        header: 'Access',
        accessor: 'email',
    },
    {
        header: 'Conts',
        accessor: 'email',
        row: {
            title: ({ row }) => `Orders ${row.ordersCount}`,
            subtitle: ({ row }) => `Reviews ${row.reviewsCount}`,
        },
    },
    {
        header: '',
        align: 'right',
        row: {
            title: ({ row }) => (
                <Button
                    leftAddons={ <OpenS /> }
                    view="outlined"
                    size="xs"
                    onClick={ () => addReview(row.id) }
                />
            ),
        },
    },
];
