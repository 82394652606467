import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import {
    Button, FlexColumns, Input, Select, showError,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { EmailSenderEnum } from '@escapenavigator/types/dist/emails/enum/email.senders';
import { enumToOptions } from '@escapenavigator/utils';
import { useApi } from 'src/providers/api/context';

import 'react-quill/dist/quill.snow.css';

type Props = {
    email: string;
    reply?: string;
    initialSubject?: string;
    cb: () => void;
};

export const EmailSendForm: React.FC<Props> = ({
    email, cb, reply, initialSubject,
}) => {
    const { emails } = useApi();
    const [text, setText] = useState('');
    const [subject, setSubject] = useState(initialSubject);
    const [sender, setSender] = useState(EmailSenderEnum.SALES);

    const { createFetch, createLoading } = useApiMethod({
        api: emails.create,
        successCallback: () => {
            setText('');
            cb();
        },
        errorCallback: ({ message }) => showError(message),
    });

    const handleSend = () => {
        createFetch({
            text,
            reply,
            to: email,
            from: sender,
            subject,
        });
    };

    return (
        <FlexColumns columns={ 1 } gr={ 12 } gc={ 0 }>
            <FlexColumns columns={ 2 } gr={ 12 } gc={ 12 }>
                <Select
                    selected={ sender }
                    label="Sender"
                    onChange={ ({ selected }) => setSender(selected.key as EmailSenderEnum) }
                    options={ enumToOptions(EmailSenderEnum) }
                    block={ true }
                />
                <Input
                    label="Subject"
                    value={ subject }
                    onChange={ (_, { value }) => setSubject(value) }
                    block={ true }
                />
            </FlexColumns>

            <ReactQuill
                value={ text || '' }
                modules={ {
                    clipboard: {
                        matchVisual: false,
                    },
                } }
                onChange={ (content) => setText(content) }
                theme="snow"
            />

            <Button
                view="primary"
                size="m"
                disabled={ !text || !subject }
                loading={ createLoading }
                onClick={ handleSend }
            >
                Send
            </Button>
        </FlexColumns>
    );
};
