import React from 'react';
import {
    Amount, CopyButton, formatDate, Status,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { getFullAddress } from '@escapenavigator/utils/dist/get-full-address';

import { getCertificateData } from './get-certificate-data';

type Props = {
    currency: ProfileCurrencyEnum;
};

export const CertificatesColumns = ({ currency }: Props): TableColumns<CertificateSaleRO> => [
    {
        header: 'Created',
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) => formatDate(row.createdAt, { format: 'dd MMMM, HH:mm', lang: 'en' }),
        },
    },

    {
        header: 'Code',
        accessor: 'certificate',
        row: {
            title: ({ row }) => <CopyButton text={ row.code } />,
            subtitle: ({ row }) => row.certificate?.title || '-',
        },
    },

    {
        header: 'Client',
        accessor: 'certificate',
        row: {
            title: ({ row }) => `${row.client?.name} ${row.client?.surname}`,
            subtitle: ({ row }) => row.client?.phone,
        },
    },

    {
        header: 'Delivery',
        accessor: 'deliveryType',
        row: {
            title: ({ row }) => getFullAddress(row),
        },
    },
    {
        header: 'Amount',
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount color="primary" type="decimal" currency={ currency } value={ row.nominal } />
            ),
            subtitle: ({ row }) =>
                (
                    <React.Fragment>
                        { 'Price' }{ ' ' }
                        <Amount
                            color="secondary"
                            view="caps"
                            type="decimal"
                            currency={ currency }
                            value={ row.total }
                        />
                    </React.Fragment>
                ) as any as string,
        },
    },

    {
        header: 'Expited',
        accessor: 'expireDate',
        sort: true,
        row: {
            title: ({ row }) => formatDate(row.expireDate, { format: 'dd MMMM yyyy', lang: 'en' }),
        },
    },
    {
        header: '',
        align: 'right',
        accessor: 'sended',
        row: {
            title: ({ row }) => {
                const data = getCertificateData(row);

                return (
                    <Status color={ data.color } view="contrast">
                        { data.title }
                    </Status>
                );
            },
        },
    },
];
