import React from 'react';

import styles from './index.module.css';

type Props = {
    header?: React.ReactNode;
};

export const ModalContainer: React.FC<Props> = ({ header, children }) => (
    <div className={ styles.container }>
        { header && <div className={ styles.header }>{ header }</div> }
        <div className={ styles.content }>{ children }</div>
    </div>
);
