/* eslint-disable complexity */
import React from 'react';
import {
    AmountInput,
    FlexColumns,
    ImageUploader,
    Input,
    Textarea,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { CreateCertificateDto } from '@escapenavigator/types/dist/certificate/create-certificate.dto';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    record?: CertificateRO;
    cb: () => void;
    close: () => void;
    t: TFunction;
};

export const CertificateModal: React.FC<Props> = ({
    record, close, t, cb,
}) => {
    const { certificates, uploads } = useApi();

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: record?.id,
        saveRequest: record?.id ? certificates.updateInside : certificates.createInside,
        removeRequest: certificates.removeInside,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    const defaultValues: Partial<CreateCertificateDto> = {
        title: '',
        validity: 356 * 3,
        onlinePaymentsCashbox: 0,
        nominals: [10],
        emailPossibility: false,
        pickupPossibility: false,
        deliveryPossibility: true,
        deliveryPrice: 500,
        allowFreeNominal: true,
        extraPrice: 2000,
        allQuestrooms: true,
        questroomsIds: [],
        awailableForNavigator: true,
        awailableForWidgets: true,
        photo: '',
        pdfLink: '',
        description: '',
        pickupInfo: '',
        deliveryInfo: '',
    };

    return (
        <RestForm
            recordId={ +record?.id }
            title={ record?.id ? record.title : 'New certificate' }
            initialValues={ serializeRecord(
                CreateCertificateDto,
                record?.id ? record : defaultValues,
            ) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Input
                        dataTestId="title"
                        label="Title"
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />

                    <AmountInput
                        dataTestId="validity"
                        label="Validity"
                        value={ values.validity }
                        min="0"
                        suffix={ t('days', { count: Number(values.validity) }) }
                        onChange={ (e, { value }) => setFieldValue('validity', value) }
                        error={ touched.validity && errors.validity }
                        block={ true }
                        required={ true }
                    />

                    <AmountInput
                        dataTestId="deliveryPrice"
                        label="Delvery price"
                        value={ values.deliveryPrice }
                        suffix={ ProfileCurrencyEnum.EUR }
                        onChange={ (e, { value }) => setFieldValue('deliveryPrice', value) }
                        block={ true }
                        type="decimal"
                    />

                    <AmountInput
                        dataTestId="extraPrice"
                        label="Extra price"
                        value={ values.extraPrice }
                        suffix={ ProfileCurrencyEnum.EUR }
                        onChange={ (e, { value }) => setFieldValue('extraPrice', value) }
                        block={ true }
                        type="decimal"
                    />

                    <Input
                        dataTestId="pdfLink"
                        label="PDF link"
                        value={ values.pdfLink }
                        onChange={ handleChange('pdfLink') }
                        error={ touched.pdfLink && errors.pdfLink }
                        required={ true }
                        block={ true }
                    />

                    <ImageUploader
                        title="Photos *"
                        value={ values.photo ? [values.photo] : [] }
                        onUpdate={ (value) => {
                            setFieldValue('photo', value[0]);
                        } }
                        setMain={ (value) => setFieldValue('photo', value) }
                        error={ touched.photo && errors.photo }
                        uploadRequest={ uploads.uploadImage }
                        type={ ImageTypeEnum.CERTIFICATE }
                        multiple={ false }
                    />

                    <Textarea
                        dataTestId="description"
                        label="Description"
                        value={ values.description }
                        onChange={ handleChange('description') }
                        block={ true }
                        maxLength={ 500 }
                        counter={ true }
                    />

                    <Textarea
                        dataTestId="deliveryInfo"
                        label="Delivery info"
                        value={ values.deliveryInfo }
                        onChange={ handleChange('deliveryInfo') }
                        error={ touched.deliveryInfo && errors.deliveryInfo }
                        block={ true }
                        maxLength={ 500 }
                        counter={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
