import React from 'react';
import { TabPanel, Tabs } from 'src/components/tabs';
import { DefaultPageProps } from 'src/types';

import { Articles } from './articles';
import { Cities } from './cities';
import { ExpertReviews } from './expert-reviews';
import { Issues } from './issues';
import { Players } from './players';
import { Sales } from './sales';
import { SocialReviews } from './social-reviews';

export const AggregatorsPage: React.FC<DefaultPageProps> = () => (
    <Tabs view="header" defaultActive="Issues">
        <TabPanel name="Issues">
            <Issues />
        </TabPanel>

        <TabPanel name="Sales">
            <Sales />
        </TabPanel>

        <TabPanel name="Expert reviews">
            <ExpertReviews />
        </TabPanel>

        <TabPanel name="Social reviews">
            <SocialReviews />
        </TabPanel>

        <TabPanel name="Cities">
            <Cities />
        </TabPanel>

        <TabPanel name="Articles">
            <Articles />
        </TabPanel>

        <TabPanel name="Players">
            <Players />
        </TabPanel>
    </Tabs>
);
