import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_RU } from './ru';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: TRANSLATIONS_RU,
            },
        },
        fallbackLng: 'ru',
    });
