import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useRoutes,
} from 'react-router-dom';
import {
    Button, EmptyPage, Menu, ModalProvider,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { REGISTRATIONS } from 'src/router/constants';
import routes from 'src/router/routes';
import { DefaultPageProps } from 'src/types';
import { convertRoutes } from 'src/utils/convert-routes';

const Routes = ({ t, current }) =>
    useRoutes([
        ...convertRoutes(routes(t, current)),
        {
            path: '*',
            element: (
                <EmptyPage
                    title="OOOPS"
                    code="404"
                    buttonText="Вернуться на главную"
                    subtitle="Похоже, что такой страницы не существует"
                    href={ REGISTRATIONS }
                />
            ),
        },
        {
            path: '/',
            element: <React.Fragment />,
        },
    ]);

export const Root: React.FC<DefaultPageProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { admins } = useApi();
    const { current } = useCurrentUser();
    const { logoutLoading, logoutFetch } = useApiMethod({
        api: admins.logout,
        successCallback: () =>
            window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/auth/admins`),
    });

    const { t } = useTranslation();

    return (
        <ModalProvider t={ t }>
            <Menu
                pathname={ location.pathname }
                t={ t }
                profileTitle={ current.name }
                userDialogOptions={ [
                    {
                        key: 'Log Out',
                        content: (
                            <Button
                                loading={ logoutLoading }
                                view="ghost"
                                onClick={ () => logoutFetch(undefined) }
                            >
                                Log out
                            </Button>
                        ),
                    },
                ] }
                profileImage="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/success.webp"
                userImage=""
                userName={ current.name }
                routes={ routes(t, current) }
                navigate={ navigate }
                Link={ Link }
            >
                <div
                    style={ {
                        height: 'calc(100% - 100px)',
                        padding: '0px 32px',
                        display: 'flex',
                        flexDirection: 'column',
                    } }
                >
                    <Routes t={ t } current={ current } />
                </div>
            </Menu>
        </ModalProvider>
    );
};
