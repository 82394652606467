import React, { useEffect, useState } from 'react';
import {
    Button,
    EmptyPage,
    Flex,
    FlexColumns,
    Loader,
    Sidepanel,
    Textarea,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { QuizDto } from '@escapenavigator/types/dist/translation/quiz.dto';
import { QuozRO } from '@escapenavigator/types/dist/translation/quiz.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { Gallery } from 'src/components/gallery';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const TranslationQuizModal = ({ close, t, cb }: Props) => {
    const { translationsApi } = useApi();
    const [data, setData] = useState<QuozRO>();
    const [error, setError] = useState('');

    const { quizFetch, quizLoading } = useApiMethod({
        api: translationsApi.quiz,
        errorCallback: ({ message }) => setError(message),
        successCallback: ({ data }) => {
            setError('');
            setData(data);
            cb();
        },
    });

    const { quizSkipFetch, quizSkipLoading } = useApiMethod({
        api: translationsApi.quizSkip,
        successCallback: ({ data }) => {
            setData(data);
            cb();
        },
    });

    useEffect(() => {
        quizFetch({
            localeId: 0,
            text: '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (data?.localeId === 0) {
        return (
            <EmptyPage
                image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/success.webp"
                title="Готово"
                subtitle="Все переводы обработаны"
                buttonText="Закрыть"
                onClick={ close }
            />
        );
    }

    if (error) {
        return <EmptyPage title="Ошибка" subtitle={ error } buttonText="Закрыть" onClick={ close } />;
    }

    if (quizLoading || quizSkipLoading || !data) return <Loader />;

    return (
        <Formik
            onSubmit={ (values) => quizFetch(values) }
            validate={ validateByDto(t) }
            enableReinitialize={ true }
            initialValues={ serializeRecord(QuizDto, {
                text: data.textToTranslate,
                localeId: data.localeId,
            }) }
        >
            { ({
                values, errors, touched, handleChange, submitForm,
            }) => (
                <Sidepanel
                    title={ languageNameByLanguage[data.locale] }
                    bottomAddons={ (
                        <Flex gap="md">
                            <Button view="primary" block={ true } size="m" onClick={ submitForm }>
                                Далее
                            </Button>

                            <Button
                                view="outlined"
                                size="m"
                                block={ true }
                                onClick={ () => quizSkipFetch(data.localeId) }
                            >
                                Пропустить
                            </Button>
                        </Flex>
                    ) }
                >
                    <FlexColumns columns={ 1 }>
                        <FlexColumns columns={ 1 } gr={ 40 } gc={ 0 }>
                            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                                <Typography.Text view="title" weight="bold">
                                    Что нужно перевести
                                </Typography.Text>

                                <Typography.Text
                                    view="primary-medium"
                                    color="primary"
                                    weight="medium"
                                >
                                    { data.originalText }
                                </Typography.Text>
                            </FlexColumns>

                            { (data.image || data.context) && (
                                <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                                    <Typography.Text view="title" weight="bold">
                                        Контекст (пояснение, где испольуется)
                                    </Typography.Text>

                                    { data.image && <Gallery src={ data.image } /> }

                                    <Typography.Text
                                        view="primary-medium"
                                        color="primary"
                                        weight="medium"
                                    >
                                        { data.context }
                                    </Typography.Text>
                                </FlexColumns>
                            ) }
                            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                                <Typography.Text view="title" weight="bold">
                                    Текст для вычетки
                                </Typography.Text>

                                <Textarea
                                    value={ values.text }
                                    onChange={ handleChange('text') }
                                    maxLength={ 3000 }
                                    counter={ true }
                                    error={ touched.text && errors.text }
                                    required={ true }
                                    block={ true }
                                />
                            </FlexColumns>
                        </FlexColumns>
                    </FlexColumns>
                </Sidepanel>
            ) }
        </Formik>
    );
};
