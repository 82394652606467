import React from 'react';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TFunction } from 'i18next';

type Props = {
    t: TFunction;
};

export const CertificateColumns = ({ t }: Props): TableColumns<CertificateRO> => [
    {
        header: 'Title',
        accessor: 'title',
        row: {
            image: 'photo',
        },
    },

    {
        header: 'Extra price',
        accessor: 'extraPrice',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    value={ row.extraPrice || 0 }
                    currency={ ProfileCurrencyEnum.EUR }
                />
            ),
        },
    },

    {
        header: 'Delivery price',
        accessor: 'deliveryPrice',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    value={ row.deliveryPrice || 0 }
                    currency={ ProfileCurrencyEnum.EUR }
                />
            ),
        },
    },

    {
        header: 'Validity',
        accessor: 'validity',
        row: {
            title: ({ row }) => `${row.validity} ${t('days', { count: +row.validity })}`,
        },
    },
];
