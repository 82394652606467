import React, { useEffect } from 'react';
import {
    Checkbox,
    FlexColumns,
    Input,
    Loader,
    Select,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { AdminRO } from '@escapenavigator/types/dist/admin/admin.ro';
import { CreateAdminDto } from '@escapenavigator/types/dist/admin/create-admin.dto';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    record?: AdminRO;
};

export const AdminModal = ({
    close, t, record, cb,
}: Props) => {
    const { admins } = useApi();

    const { translationsApi } = useApi();

    const { getAllNamespacesData, getAllNamespacesFetch, getAllNamespacesLoading } = useApiMethod({
        api: translationsApi.getAllNamespaces,
    });

    useEffect(() => {
        getAllNamespacesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: record?.id ? admins.update : admins.create,
        removeRequest: admins.remove,
        recordId: record?.id,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    if (!getAllNamespacesData) return <Loader />;

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            loading={ getAllNamespacesLoading || !getAllNamespacesData }
            save={ save }
            softError={ softError }
            recordId={ record?.id }
            remove={ remove }
            validate={ validateByDto(t) }
            title={ record?.id ? record.name : 'New admin' }
            close={ close }
            initialValues={ serializeRecord(CreateAdminDto, record || {}) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label="Email"
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Contact name"
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Contact phone"
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Password"
                            value={ values.password }
                            onChange={ handleChange('password') }
                            error={ touched.password && errors.password }
                            required={ true }
                            block={ true }
                        />

                        <Select
                            multiple={ true }
                            block={ true }
                            label="Namsespaces"
                            options={ getAllNamespacesData.map((n) => ({
                                key: n.id,
                                content: n.title,
                            })) }
                            selected={ values.namsespaces }
                            allowUnselect={ true }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'namsespaces',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            optionsListWidth="field"
                        />

                        <Select
                            multiple={ true }
                            block={ true }
                            label="Countries"
                            options={ enumToOptions(CountriesEnum) }
                            selected={ values.countries }
                            allowUnselect={ true }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'countries',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            optionsListWidth="field"
                        />

                        <Select
                            multiple={ true }
                            block={ true }
                            label="Languages"
                            options={ languageOptions(Object.values(Languages)) }
                            selected={ values.languages }
                            allowUnselect={ true }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'languages',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            optionsListWidth="field"
                        />

                        <Checkbox
                            label="Total access"
                            checked={ values.totalAccess }
                            onChange={ (e, { checked }) => setFieldValue('totalAccess', checked) }
                            block={ true }
                        />

                        { !values.totalAccess && (
                            <React.Fragment>
                                <Checkbox
                                    label="Can use translations"
                                    checked={ values.canUseTranslations }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseTranslations', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Now Escape"
                                    checked={ values.nowEscape }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('nowEscape', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use registration requests"
                                    checked={ values.canUseRegistrationRequests }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseRegistrationRequests', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use profiles"
                                    checked={ values.canUseProfiles }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseProfiles', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use profile verification"
                                    checked={ values.canUseVerificationPage }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseVerificationPage', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use community reviews"
                                    checked={ values.canUseCommunityReviews }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseCommunityReviews', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use reviews"
                                    checked={ values.canUseReviews }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseReviews', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use agb"
                                    checked={ values.canUseAgb }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseAgb', checked) }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Can use articles"
                                    checked={ values.canUseArticles }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('canUseArticles', checked) }
                                    block={ true }
                                />
                            </React.Fragment>
                        ) }
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
