import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ArticleRO } from '@escapenavigator/types/dist/article/article.ro';

export const columns: TableColumns<ArticleRO> = [
    {
        header: 'Title',
        accessor: 'title',
        sort: true,
        row: {
            image: 'poster',
            subtitle: 'description',
        },
    },
    {
        header: 'Country',
        accessor: 'language',
        sort: true,
        row: {
            title: ({ row }) => row.language,
            subtitle: ({ row }) => row.cities?.join(', '),
        },
    },
];
