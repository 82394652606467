import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Checkbox, FiltersForm, FlexColumns, Loader, Select, showError,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileTagEnum } from '@escapenavigator/types/dist/profile/enum/profile-tag.enum';
import { QueryAdminProfileDto } from '@escapenavigator/types/dist/profile/query-admin-profile.dto';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { useApi } from 'src/providers/api/context';

type Props = {
    submitCallback: (values: QueryAdminProfileDto & Record<string, unknown>) => void;
    initialValues: QueryAdminProfileDto & Record<string, unknown>;
    close: () => void;
};

export const FiltersOrganizationModal: React.FC<Props> = ({
    submitCallback,
    close,
    initialValues,
}) => {
    const { t } = useTranslation();
    const { profiles } = useApi();

    const { getProfileFiltersData, getProfileFiltersFetch, getProfileFiltersLoading } =
        useApiMethod({
            api: profiles.getProfileFilters,
            errorCallback: ({ message }) => showError(message),
        });

    useEffect(() => {
        getProfileFiltersFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getProfileFiltersData || getProfileFiltersLoading) {
        return <Loader />;
    }

    return (
        <FiltersForm
            submitCallback={ submitCallback }
            initialValues={ initialValues }
            defaultValues={ initialValues }
            close={ close }
            t={ t }
        >
            { ({ values, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        selected={ values.status }
                        label="Status"
                        multiple={ true }
                        allowUnselect={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'status',
                                (selectedMultiple || []).map(({ key }) => key),
                            ) }
                        options={ Object.entries(getProfileFiltersData.status).map(([key, val]) => ({
                            key,
                            content: `${key} ${val}`,
                        })) }
                        required={ true }
                        block={ true }
                    />

                    <Select
                        selected={ values.subscriptionStatus }
                        multiple={ true }
                        allowUnselect={ true }
                        label="Subscription Status"
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'subscriptionStatus',
                                (selectedMultiple || []).map(({ key }) => key),
                            ) }
                        options={ Object.entries(getProfileFiltersData.subscriptionStatus).map(
                            ([key, val]) => ({
                                key,
                                content: `${key} ${val}`,
                            }),
                        ) }
                        required={ true }
                        block={ true }
                    />

                    <Select
                        dataTestId="crm"
                        selected={ values.crm }
                        allowUnselect={ true }
                        label="CRM"
                        multiple={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'crm',
                                selectedMultiple.map((s) => s.key),
                            ) }
                        options={ Object.entries(getProfileFiltersData.crm)
                            .sort((a, b) => (a[0] < b[0] ? 1 : -1))
                            .map(([key, val]) => ({
                                key,
                                content: `${key.toLowerCase()}: ${val}`,
                            })) }
                        block={ true }
                    />

                    <Select
                        selected={ values.tags }
                        allowUnselect={ true }
                        label="Tags"
                        multiple={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'tags',
                                selectedMultiple.map((s) => s.key),
                            ) }
                        options={ enumToOptions(ProfileTagEnum) }
                        block={ true }
                    />

                    <Select
                        allowUnselect={ true }
                        selected={ values.country }
                        label="Country"
                        options={ enumToOptions(CountriesEnum) }
                        onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                    />

                    <Select
                        selected={ values.partnerProgram }
                        allowUnselect={ true }
                        label="Partner programm"
                        options={ Object.entries(getProfileFiltersData.partnerProgram).map(
                            ([key, val]) => ({
                                key,
                                content: `${key} ${val}`,
                            }),
                        ) }
                        onChange={ ({ selected }) => setFieldValue('partnerProgram', selected?.key) }
                    />

                    <Select
                        selected={ values.integrationType }
                        label="Integration type"
                        options={ Object.entries(getProfileFiltersData.integrationType).map(
                            ([key, val]) => ({
                                key,
                                content: `${key} ${val}`,
                            }),
                        ) }
                        onChange={ ({ selected }) => setFieldValue('integrationType', selected?.key) }
                    />

                    <Checkbox
                        label="Canceled soon"
                        checked={ values.subscriptionCancelationDate }
                        onChange={ (e, { checked }) =>
                            setFieldValue('subscriptionCancelationDate', checked ? true : undefined) }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </FiltersForm>
    );
};
