import React from 'react';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';
import { format } from 'date-fns';

import { EmailStatus } from '../email-status';
import { getEmailIcons } from '../inbox/columns';
import { EmailTableButtons } from '../profile-button';

export const getEmailText = (email: EmailRO) => {
    if (email.status !== EmailStatusEnum.INBOX) {
        return (JSON.parse(email.text || '').sections?.[0]?.text || '').slice(0, 100);
    }

    return (email.text || '').slice(0, 100);
};

export const columns: TableColumns<EmailRO> = [
    {
        header: 'To',
        accessor: 'status',
        width: '200px',
        row: {
            icon: ({ row }) => getEmailIcons(row),
            subtitle: ({ row }) => format(new Date(row.createdAt), 'dd MMMM yyyy, HH:mm'),
            title: ({ row }) => row.to,
        },
    },
    {
        header: 'Subject',
        accessor: 'subject',
        width: '200px',
        row: {
            subtitle: ({ row }) => getEmailText(row),
            title: ({ row }) => row.subject,
        },
    },
    {
        header: 'Status',
        accessor: 'status',
        width: '100px',
        row: {
            title: ({ row }) => <EmailStatus email={ row } />,
        },
    },
    {
        header: 'Actions',
        accessor: 'replied',
        width: '100px',
        row: {
            title: ({ row }) => <EmailTableButtons email={ row } />,
        },
    },
];
