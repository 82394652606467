import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput,
    Checkbox,
    FlexColumns,
    Input,
    Select,
    Textarea,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateProfileActionRO } from '@escapenavigator/types/dist/profile/action/create-profile-action.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { format } from 'date-fns';
import { useApi } from 'src/providers/api/context';

type OrganizationModalProps = {
    close: () => void;
    cb: () => void;
    profileId: number;
    prevActionId: number;
};

export const OrganizationActionModal = ({
    close,
    profileId,
    prevActionId,
    cb,
}: OrganizationModalProps) => {
    const { profiles } = useApi();
    const { t, i18n } = useTranslation();

    const { createActionLoading, createActionFetch, createActionError } = useApiMethod({
        api: profiles.createAction,
        successCallback: () => {
            close();
            if (cb) cb();
        },
    });

    const types = ['email', 'call', 'presentation', 'check'];

    return (
        <RestForm
            t={ t }
            updating={ createActionLoading }
            save={ (values) => createActionFetch({ data: values }) }
            softError={ createActionError?.message }
            validate={ validateByDto(t) }
            title={ prevActionId ? 'Close activity' : 'New activity' }
            close={ close }
            initialValues={ serializeRecord(CreateProfileActionRO, {
                profileId,
                prevActionStatus: false,
                prevResult: '',
                description: '',
                prevActionId,
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm'),
            }) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        { !!prevActionId && (
                            <React.Fragment>
                                <Textarea
                                    label="Result"
                                    value={ values.prevResult }
                                    onChange={ handleChange('prevResult') }
                                    error={ touched.prevResult && errors.prevResult }
                                    block={ true }
                                />
                                <Checkbox
                                    label="Succeed?"
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('prevActionStatus', checked) }
                                    checked={ values.prevActionStatus }
                                />
                                <Typography.Title tag="div" view="xsmall" weight="bold">
                                    Next activity
                                </Typography.Title>
                            </React.Fragment>
                        ) }

                        <Select
                            dataTestId="type"
                            selected={ values.type }
                            label="Type"
                            onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                            options={ types.map((key) => ({ key, content: key.toUpperCase() })) }
                            error={ touched.type && errors.type }
                            block={ true }
                        />

                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <CalendarInput
                                dataTestId="date"
                                label="Date"
                                value={ values.date }
                                onChange={ (e, { value }) => {
                                    setFieldValue('date', value);
                                } }
                                block={ true }
                                error={ touched.date && errors.date }
                                lang={ i18n.language }
                            />

                            <Input
                                label="Time"
                                value={ values.time }
                                onChange={ handleChange('time') }
                                error={ touched.time && errors.time }
                                block={ true }
                            />
                        </FlexColumns>

                        <Textarea
                            label="Description"
                            value={ values.description }
                            onChange={ handleChange('description') }
                            error={ touched.description && errors.description }
                            block={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
