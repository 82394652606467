import React from 'react';
import { Flex, Typography } from '@alphakits/ui';
import { NowEscapeProfileRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-profile.ro';
import { ModalContainer } from 'src/components/modal-container';
import { TabPanel, Tabs } from 'src/components/tabs';

import { Orders } from './orders';
import { Questrooms } from './questrooms';

type Props = {
    profile: NowEscapeProfileRO;
};

export const NowEscapeProfile = ({ profile }: Props) => (
    <ModalContainer
        header={ (
            <Flex direction="column" align="start" justify="start">
                <Typography.Title view="xxsmall" tag="div" weight="bold">
                    { profile.title }
                </Typography.Title>

                <Typography.Text view="caps" color="secondary">
                    { profile.country }
                </Typography.Text>
            </Flex>
        ) }
    >
        <Tabs defaultActive="Questrooms">
            <TabPanel name="Questrooms">
                <Questrooms profile={ profile } />
            </TabPanel>

            <TabPanel name="Orders">
                <Orders profile={ profile } />
            </TabPanel>
        </Tabs>
    </ModalContainer>
);
