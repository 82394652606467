import React from 'react';
import {
    FlexColumns, Status, StatusProps, Tooltip, Typography,
} from '@alphakits/ui';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';
import { format } from 'date-fns';

const status: Record<EmailStatusEnum, StatusProps['color']> = {
    [EmailStatusEnum.BOUNCED]: 'orange',
    [EmailStatusEnum.CLICK]: 'green',
    [EmailStatusEnum.INBOX]: 'blue',
    [EmailStatusEnum.OPENED]: 'purple',
    [EmailStatusEnum.PENDING]: 'grey',
    [EmailStatusEnum.REJECTED]: 'orange',
    [EmailStatusEnum.SENT]: 'blue',
    [EmailStatusEnum.SOFT_BOUNCED]: 'orange',
    [EmailStatusEnum.SPAM]: 'red',
};

export const EmailStatus = ({ email }: { email: EmailRO }) => (
    <Tooltip
        trigger="hover"
        position="bottom-end"
        content={ (
            <div style={ { width: 300, overflow: 'auto' } }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <Typography.Text view="title" weight="bold">
                            Opens { email.opens.length }
                        </Typography.Text>

                        { email.opens.map((date) => (
                            <Typography.Text view="caps" weight="regular">
                                { format(new Date(+`${date}000`), 'dd MMMM yyyy, HH:mm') }
                            </Typography.Text>
                        )) }
                    </FlexColumns>

                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <Typography.Text view="title" weight="bold">
                            Clicks { email.clicks.length }
                        </Typography.Text>

                        { email.clicks.map((url) => (
                            <Typography.Text view="caps" weight="regular">
                                { url }
                            </Typography.Text>
                        )) }
                    </FlexColumns>
                </FlexColumns>
            </div>
        ) }
    >
        <div>
            <Status color={ status[email.status] } view="contrast">
                { email.status.toUpperCase() }
            </Status>
        </div>
    </Tooltip>
);
