import React from 'react';
import { Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateNamespaceDto } from '@escapenavigator/types/dist/translation/create-namespace.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const TranslationNamespaceModal = ({ close, t, cb }: Props) => {
    const { translationsApi } = useApi();

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: translationsApi.createNamespace,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Create namespace"
            close={ close }
            initialValues={ serializeRecord(CreateNamespaceDto, {}) }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <Input
                    dataTestId="title"
                    label="Title"
                    value={ values.title }
                    onChange={ handleChange('title') }
                    error={ touched.title && errors.title }
                    required={ true }
                    block={ true }
                />
            ) }
        </RestForm>
    );
};
