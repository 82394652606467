/* eslint-disable complexity */
import React from 'react';
import {
    BookM,
    ChecklistM,
    LetterM,
    ListM,
    PersonsTwoM,
    PhoneM,
    PlanetM,
    SecurePaymentM,
    SkullM,
} from '@alphakits/icons';
import { CurrentAdminRO } from '@escapenavigator/types/dist/admin/current-admin.ro';
import { TFunction } from 'i18next';
import { ActionsPage } from 'src/pages/actions';
import { AdminsPage } from 'src/pages/admins';
import { AgbsPage } from 'src/pages/agbs';
import { AggregatorsPage } from 'src/pages/aggregators';
import { Bills } from 'src/pages/bills';
import { CertificatesPage } from 'src/pages/certificates';
import { DashboardPage } from 'src/pages/dashboard';
import { EmailService } from 'src/pages/email-service';
import { EmailsPage } from 'src/pages/emails';
import { NowEscape } from 'src/pages/now-escape';
import { OrganizationsPage } from 'src/pages/organizations';
import { ProfileCrmsPage } from 'src/pages/profile-crms';
import { QuestroomsPage } from 'src/pages/questrooms';
import { RegistrationsPage } from 'src/pages/registrations';
import { TranslationsPage } from 'src/pages/translations';

import {
    ACTIONS,
    ADMINS,
    AGBS,
    AGGREGATORS,
    BILLS,
    CERTIFICATE_SERVICE,
    CRMS,
    DASHBOARD,
    EMAIL_SERVICE,
    MARKETING,
    NOW_ESCAPE,
    ORGANIZATIONS,
    QUESTROOMS,
    REGISTRATIONS,
    TRANSLATIONS,
} from './constants';

const routes = (t: TFunction, currentUser: CurrentAdminRO) => {
    const res = [];

    if (currentUser.totalAccess || currentUser.canUseProfiles) {
        res.push({
            path: DASHBOARD,
            element: <DashboardPage />,
            icon: <PersonsTwoM />,
        });
    }

    if (currentUser.totalAccess) {
        res.push({
            path: EMAIL_SERVICE,
            element: <EmailService t={ t } />,
            icon: <LetterM />,
        });
    }

    if (currentUser.nowEscape) {
        res.push({
            path: NOW_ESCAPE,
            element: <NowEscape />,
            icon: <BookM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseProfiles) {
        res.push({
            path: ACTIONS,
            element: <ActionsPage />,
            icon: <PhoneM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseRegistrationRequests) {
        res.push({
            path: REGISTRATIONS,
            element: <RegistrationsPage t={ t } />,
            icon: <ChecklistM />,
        });
    }

    if (currentUser.totalAccess) {
        res.push({
            path: AGGREGATORS,
            element: <AggregatorsPage t={ t } />,
            icon: <ChecklistM />,
        });
    }

    if (currentUser.totalAccess) {
        res.push({
            path: CERTIFICATE_SERVICE,
            element: <CertificatesPage />,
            icon: <ChecklistM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseProfiles) {
        res.push({
            path: ORGANIZATIONS,
            element: <OrganizationsPage t={ t } />,
            icon: <PersonsTwoM />,
        });
        res.push({
            path: QUESTROOMS,
            element: <QuestroomsPage t={ t } />,
            icon: <SkullM />,
        });
    }

    if (currentUser.totalAccess) {
        res.push({
            path: MARKETING,
            element: <EmailsPage t={ t } />,
            icon: <LetterM />,
        });

        res.push({
            path: BILLS,
            element: <Bills t={ t } />,
            icon: <SecurePaymentM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseAgb) {
        res.push({
            path: AGBS,
            element: <AgbsPage t={ t } />,
            icon: <ListM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseTranslations) {
        res.push({
            path: TRANSLATIONS,
            element: <TranslationsPage t={ t } />,
            icon: <PlanetM />,
        });
    }

    if (currentUser.totalAccess) {
        res.push({
            path: ADMINS,
            element: <AdminsPage t={ t } />,
            icon: <PersonsTwoM />,
        });
    }

    if (currentUser.totalAccess || currentUser.canUseProfiles) {
        res.push({
            path: CRMS,
            element: <ProfileCrmsPage t={ t } />,
            icon: <PersonsTwoM />,
        });
    }

    return res;
};

export default routes;
