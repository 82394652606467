import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersForm, FlexColumns, Select } from '@alphakits/ui';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikValues } from 'formik';

type Props = {
    submitCallback: (values: FormikValues) => void;
    initialValues: Record<string, unknown>;
    close: () => void;
};

const defaultValues = {
    country: '',
};

export const FiltersQuestroomModal: React.FC<Props> = ({
    submitCallback,
    close,
    initialValues,
}) => {
    const { t } = useTranslation();

    return (
        <FiltersForm
            submitCallback={ submitCallback }
            initialValues={ initialValues || {} }
            close={ close }
            defaultValues={ defaultValues || {} }
            t={ t }
        >
            { ({ values, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        selected={ values.country }
                        label="Country"
                        options={ enumToOptions(CountriesEnum) }
                        onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                    />
                </FlexColumns>
            ) }
        </FiltersForm>
    );
};
