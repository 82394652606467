import React, { useEffect } from 'react';
import {
    convertToOptions, FlexColumns, Loader, Select, showError,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const TranslationUpdateNamespaceModal = ({ close, t, cb }: Props) => {
    const { translationsApi } = useApi();

    const { getAllNamespacesData, getAllNamespacesFetch } = useApiMethod({
        api: translationsApi.getAllNamespaces,
    });

    const { removeNamespaceFetch, removeNamespaceLoading } = useApiMethod({
        api: translationsApi.removeNamespace,
        successCallback: cb,
    });

    const { regenerateNamespaceFetch, regenerateNamespaceLoading } = useApiMethod({
        api: translationsApi.regenerateNamespace,
        successCallback: cb,
    });

    const { addMissingLanguagesLoading, addMissingLanguagesFetch } = useApiMethod({
        api: translationsApi.addMissingLanguages,
        successCallback: cb,
        errorCallback: ({ message }) => showError(message),
    });

    // const { updateAllTranslationsInNamespaceFetch, updateAllTranslationsInNamespaceLoading } =
    //     useApiMethod({
    //         api: translationsApi.updateAllTranslationsInNamespace,
    //         successCallback: cb,
    //     });

    useEffect(() => {
        getAllNamespacesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getAllNamespacesData) return <Loader />;

    return (
        <RestForm
            t={ t }
            updating={
                removeNamespaceLoading || regenerateNamespaceLoading || addMissingLanguagesLoading
            }
            save={ ({ type, namespaceId }) => {
                if (type === 'remove') {
                    removeNamespaceFetch(namespaceId);
                }
                if (type === 'chaneExisting') {
                    regenerateNamespaceFetch(namespaceId);
                }
                if (type === 'addMissing') {
                    addMissingLanguagesFetch(namespaceId);
                }
            } }
            loading={ !getAllNamespacesData }
            validate={ validateByDto(t) }
            title="Update namespace"
            close={ close }
            initialValues={ { namespaceId: '', type: 'remove' } }
        >
            { ({
                values, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        dataTestId="namespaceId"
                        selected={ values.namespaceId }
                        label="Namespace"
                        onChange={ ({ selected }) => setFieldValue('namespaceId', selected?.key) }
                        options={ convertToOptions(getAllNamespacesData) }
                        error={ touched.namespaceId && errors.namespaceId }
                        required={ true }
                        block={ true }
                    />

                    <Select
                        dataTestId="type"
                        selected={ values.type }
                        label="Type"
                        onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                        options={ [
                            {
                                key: 'remove',
                                content: 'Remove',
                            },
                            {
                                key: 'chaneExisting',
                                content: 'Regenerate',
                            },
                            {
                                key: 'addMissing',
                                content: 'Add missing languages',
                            },
                        ] }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
