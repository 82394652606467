import React, { useCallback, useContext, useState } from 'react';
import {
    Button, Flex, ModalContext, Table, Typography,
} from '@alphakits/ui';
import { FiltersOrganizationModal } from 'src/modals/filters/organization';
import { OrganizationInfoModal } from 'src/modals/organization-info/main';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const OrganizationsPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);
    const { profiles, admins } = useApi();
    const [selectedProfilesIds, setProfilesIds] = useState([]);

    const openOrganizationModal = openModal(OrganizationInfoModal, 'm');

    // const { getAllCrmFetch, getAllCrmLoading, getAllCrmData } = useApiMethod({
    //     api: profiles.getAllCrm,
    //     errorCallback: ({ message }) => showError(message),
    // });

    // useEffect(() => {
    //     getAllCrmFetch(undefined);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const loginAsUser = useCallback(
        async (profileId: number) => {
            try {
                await admins.getProfileToken(profileId);
                window.location.assign(`${process.env.REACT_APP_APP_DOMAIN}`);
            } catch (error) {
                alert('Error');
            }
        },
        [admins],
    );

    const addOrRemoveProfile = (id: number) => {
        if (selectedProfilesIds.includes(id)) {
            setProfilesIds(selectedProfilesIds.filter((p) => p !== id));
        } else {
            setProfilesIds([...selectedProfilesIds, id]);
        }
    };

    return (
        <React.Fragment>
            <Table.TableComponent
                leftHeaderAddons={
                    !!selectedProfilesIds.length && (
                        <Flex gap="md">
                            <Typography.Text view="caps">
                                <b>{ selectedProfilesIds.length }</b> selected
                            </Typography.Text>
                            <Button size="xs" view="outlined" onClick={ () => {} }>
                                Send email
                            </Button>
                        </Flex>
                    )
                }
                tagsBuilder={ mapper() }
                filtersButtonLabel="Filters"
                onFiltersClick={ ({ initialValues, submitCallback }) => {
                    openModal(FiltersOrganizationModal)({
                        initialValues: initialValues.where as any,
                        submitCallback,
                    });
                } }
                onRowClick={ (row) => openOrganizationModal({ profileId: row.id }) }
                columns={ columns({ loginAsUser, addOrRemoveProfile, selectedProfilesIds }) }
                searchPlaceholder="Find by title or phone"
                promise={ (values) => profiles.query(values) }
                language="en"
            />
        </React.Fragment>
    );
};
