import React from 'react';
import { Amount, Image, Status } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { AggregatorOrderRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-order.ro';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';

import escapeNavigator from './escapeNavigator.png';
import nowEscape from './nowEscape.png';

const images = {
    [SourceEnum.AGREGATOR]: escapeNavigator,
    [SourceEnum.nowEscape]: nowEscape,
};

export const ordersColumns = (): TableColumns<AggregatorOrderRO> => [
    {
        header: 'Date',
        accessor: 'utcDate',
        row: {
            title: ({ row: { questroom } }) => {
                if (!questroom) return 'Deleted escape room';

                return questroom?.title;
            },
            subtitle: ({ row }) => row.profile?.title,
            icon: ({ row }) => <Image view="ellipse" src={ images[row.source] } />,
        },
    },
    {
        header: 'Code',
        accessor: 'code',
        row: {
            title: ({ row }) => row.code,
            subtitle: ({ row }) => `ID ${row.otherId}`,
        },
    },

    {
        header: 'Totla',
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    weight="bold"
                    color="primary"
                    currency="EUR"
                    value={ row.total }
                />
            ),
            subtitle: ({ row }) =>
                (
                    <React.Fragment>
                        Paid{ ' ' }
                        <Amount
                            type="decimal"
                            currencyOpacity={ false }
                            view="caps"
                            currency="EUR"
                            value={ row.payed }
                        />
                    </React.Fragment>
                ) as any,
        },
    },
    {
        header: '',
        accessor: 'slotId',
        align: 'right',
        row: {
            title: ({ row }) => (
                <Status view="contrast" color={ row.slotId ? 'green' : 'grey' }>
                    { row.slotId ? 'Accepted' : 'Canceled' }
                </Status>
            ),
        },
    },
];
