/* eslint-disable no-new */
import React, { useEffect, useRef, useState } from 'react';
import { Sidepanel } from '@alphakits/ui';
import RrwebPlayer from 'rrweb-player';

import 'rrweb-player/dist/style.css';

export const OrganizationActivityModal = ({ sessionUrl }) => {
    const playerRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                setLoading(true);
                setError(null);

                const response = await fetch(sessionUrl, { mode: 'cors' });

                const data = await response.json();

                setEvents(data.events);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSessionData();
    }, [sessionUrl]);

    useEffect(() => {
        if (!loading && playerRef.current && events.length > 0) {
            // Инициализация плеера rrweb-player
            console.log(events);
            new RrwebPlayer({
                target: playerRef.current,

                props: {
                    autoPlay: true,
                    speed: 1,
                    events,
                },
            });
        }
    }, [events, playerRef, loading]);

    return (
        <Sidepanel subtitle={ error } title="Replay Recording" loading={ loading }>
            <div
                ref={ playerRef }
                style={ {
                    margin: '0 auto',
                    width: '100%',
                    height: '100%',
                } }
            />
        </Sidepanel>
    );
};
