import React, { useEffect } from 'react';
import { Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const Bills: React.FC<DefaultPageProps> = () => {
    const { profiles } = useApi();

    const { paginateBillsData, paginateBillsFetch, paginateBillsLoading } = useApiMethod({
        api: profiles.paginateBills,
    });

    useEffect(() => {
        paginateBillsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ Columns() }
                loading={ paginateBillsLoading }
                searchPlaceholder="Serach by title"
                language="en"
                records={ paginateBillsData || [] }
            />
        </React.Fragment>
    );
};
