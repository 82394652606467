import React, { useContext, useEffect } from 'react';
import { Loader, ModalContext, Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { AgbModal } from 'src/modals/agb';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const AgbsPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const openAgbModal = openModal(AgbModal, 'm');

    const { agbs } = useApi();

    const { getAllData, getAllLoading, getAllFetch } = useApiMethod({
        api: agbs.getAll,
    });

    useEffect(() => {
        getAllFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (getAllLoading || !getAllData) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <Table.TableComponent
                onRowClick={ ({ id }) =>
                    openAgbModal({ recordId: +id, cb: () => getAllFetch(undefined) }) }
                onCreateClick={ () => openAgbModal({ cb: () => getAllFetch(undefined) }) }
                columns={ columns }
                searchPlaceholder="Serach"
                language="en"
                records={ getAllData }
            />
        </React.Fragment>
    );
};
