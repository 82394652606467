import React, { useContext, useEffect } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { AdminModal } from 'src/modals/admin';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const AdminsPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const openRequest = openModal(AdminModal);

    const { admins } = useApi();

    const { queryData, queryFetch, queryLoading } = useApiMethod({
        api: admins.query,
    });

    useEffect(() => {
        queryFetch(undefined);
    }, [queryFetch]);

    return (
        <Table.TableComponent
            tagsBuilder={ mapper() }
            onRowClick={ (record) => openRequest({ record, cb: () => queryFetch(undefined) }) }
            onCreateClick={ () => openRequest() }
            columns={ columns }
            searchPlaceholder="Serach by title"
            language="en"
            loading={ queryLoading }
            records={ queryData || [] }
        />
    );
};
