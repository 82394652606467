import React from 'react';
import ReactDOM from 'react-dom';
import { defaultThemes, THEME, ThemeProvider } from '@alphakits/ui/dist/theme-provider';

import { ApiProvider } from './providers/api/context';
import App from './app';

// import reportWebVitals from './report-web-vitals';
import './index.css';
import '@alphakits/ui/dist/index.css';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider themes={ defaultThemes } selectedTheme={ THEME.LIGHT }>
            <ApiProvider>
                <App />
            </ApiProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
