import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCrmRO } from '@escapenavigator/types/dist/profile/profile-crm/profile-crm.ro';

export const columns: TableColumns<ProfileCrmRO> = [
    {
        header: 'Title',
        accessor: 'title',
        sort: true,
    },
    {
        header: 'Profiles',
        accessor: 'profiles',
        sort: true,
    },
];
