import React, { useContext } from 'react';
import { CheckmarkS, InfoMarkS } from '@alphakits/icons';
import {
    Box,
    Button,
    Flex,
    FlexColumns,
    ModalContext,
    Status,
    Tooltip,
    Typography,
} from '@alphakits/ui/dist';
import { ProfileActionRO } from '@escapenavigator/types/dist/profile/action/profile-action.dto';
import { format } from 'date-fns';
import { OrganizationActionModal } from 'src/modals/organization-action';

type Props = {
    action: ProfileActionRO;
    profileId: number;
    cb: () => void;
};

export const ActionCell: React.FC<Props> = ({ action, profileId, cb }) => {
    const { openModal } = useContext(ModalContext);

    return (
        <Box border={ true } rounded="md" padding="md">
            <FlexColumns columns={ 1 } gr={ 8 }>
                <Flex gap="md">
                    <Flex gap="md" justify="start">
                        <Flex justify="start" gap="xs">
                            <Status view="contrast" color={ action.done ? 'green' : 'red' }>
                                { action.type.toUpperCase() }
                            </Status>

                            <span>
                                { format(new Date(action.date), 'dd MMMM yyyy') } { action.time }
                            </span>
                            <Tooltip
                                trigger="hover"
                                position="bottom-end"
                                content={ (
                                    <div style={ { width: 300, overflow: 'auto' } }>
                                        <FlexColumns columns={ 1 } gr={ 16 }>
                                            <Typography.Text view="caps" color="secondary">
                                                Created{ ' ' }
                                                { format(
                                                    new Date(action.createdAt),
                                                    'dd MMMM yyyy, HH:mm',
                                                ) }
                                                , { action.author }
                                            </Typography.Text>
                                        </FlexColumns>
                                    </div>
                                ) }
                            >
                                <InfoMarkS />
                            </Tooltip>
                        </Flex>
                    </Flex>

                    { !action.done && (
                        <Button
                            view="outlined"
                            leftAddons={ <CheckmarkS /> }
                            size="xs"
                            onClick={ () => {
                                openModal(OrganizationActionModal)({
                                    profileId,
                                    prevActionId: action.id,
                                    cb,
                                });
                            } }
                        />
                    ) }
                </Flex>
                <Typography.Text view="title">{ action.description }</Typography.Text>

                { action.result && (
                    <Typography.Text view="title">
                        <b>Result</b>: { action.result }
                    </Typography.Text>
                ) }
            </FlexColumns>
        </Box>
    );
};
