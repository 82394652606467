import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { TestSendingModal } from 'src/modals/test-sending';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const EmailsPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);
    const { profiles } = useApi();
    const openEmail = openModal(TestSendingModal);

    return (
        <React.Fragment>
            <Table.TableComponent
                tagsBuilder={ mapper() }
                onCreateClick={ () => openEmail({ cb: () => openEmail() }) }
                columns={ Columns() }
                searchPlaceholder="Serach by title"
                language="en"
                promise={ profiles.queryCampaigns }
            />
        </React.Fragment>
    );
};
