import React from 'react';
import { OpenS } from '@alphakits/icons';
import { Button, Checkbox } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { AdminProfileRO } from '@escapenavigator/types/dist/profile/admin-profile.ro';
import { differenceInDays } from 'date-fns';

export const columns = ({
    loginAsUser,
    addOrRemoveProfile,
    selectedProfilesIds = [],
}): TableColumns<AdminProfileRO> => [
    {
        header: 'Name',
        accessor: 'title',
        sort: true,
        row: {
            title: ({ row }) => `${row.questroomsCount ? '' : '[CLOSED] '} ${row.title}`,
            subtitle: ({ row }) => `${row.country.toUpperCase()}, ${row.crm}`,
            icon: ({ row }) => (
                <div
                    onClick={ (e) => {
                        e.stopPropagation();
                    } }
                    role="button"
                    aria-hidden="true"
                >
                    <Checkbox
                        onChange={ () => {
                            addOrRemoveProfile(row.id);
                        } }
                        checked={ selectedProfilesIds.includes(row.id) }
                    />
                </div>
            ),
        },
    },
    {
        header: '',
        accessor: 'site',
        sort: true,
        row: {
            title: ({ row }) =>
                (row.site ? (
                    <Button
                        leftAddons={ <OpenS /> }
                        view="link"
                        target="_blank"
                        size="xs"
                        href={ `${row.site}` }
                        onClick={ (e) => e.stopPropagation() }
                    />
                ) : null),
        },
    },
    {
        header: 'Email',
        accessor: 'mainEmail',
        sort: true,
        row: {
            title: 'mainEmail',
        },
    },
    {
        header: 'Step',
        accessor: 'status',
        sort: true,
        row: {
            title: ({ row }) => row.status?.toUpperCase(),
        },
    },

    {
        header: 'Last visit',
        accessor: 'lastVisit',
        sort: true,
        row: {
            title: ({ row }) =>
                row.lastVisit && `${differenceInDays(new Date(), new Date(row.lastVisit))} days`,
        },
    },
    {
        header: 'Questrooms',
        accessor: 'questroomsCount',
        sort: true,
        row: {
            title: ({ row }) => `${row.questroomsCount} / ${row.questroomsDeletedCount}`,
            subtitle: ({ row }) => `Locations: ${row.locationsCount}`,
        },
    },
    {
        header: '',
        align: 'right',
        row: {
            title: ({ row }) => (
                <Button
                    leftAddons={ <OpenS /> }
                    view="outlined"
                    size="xs"
                    onClick={ (e) => {
                        e.stopPropagation();
                        loginAsUser(row.id);
                    } }
                />
            ),
        },
    },
];
