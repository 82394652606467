import React, { useEffect, useState } from 'react';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CurrentAdminRO } from '@escapenavigator/types/dist/admin/current-admin.ro';
import { useApi } from 'src/providers/api/context';
import { Preloader } from 'src/templates/preloader';

type Context = {
    current?: CurrentAdminRO;
    setUser?: (user: CurrentAdminRO) => void;
};

export const CurrentUserContext = React.createContext<Context>({});

export const CurrentUserProvider = ({ children }) => {
    const { admins } = useApi();
    const [current, setCurrent] = useState<CurrentAdminRO>();

    const { meFetch, meLoading, meError } = useApiMethod({
        api: admins.me,
        successCallback: ({ data }) => setCurrent(data),
    });

    useEffect(() => {
        meFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (meError) {
            window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/auth/admins`);
        }
    }, [meError]);

    if (meLoading || !current) return <Preloader />;

    return (
        <CurrentUserContext.Provider
            value={ {
                current,
                setUser: setCurrent,
            } }
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
