import React from 'react';
import { FlexColumns, Padding, Typography } from '@alphakits/ui/dist';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';

import { ViewEmail } from '../view-email';

type Props = {
    email: EmailRO;
};

const Body = ({ email }: Props) => {
    if (email.status !== EmailStatusEnum.INBOX) {
        return (
            <ViewEmail text={ JSON.parse(email.text || '').sections[0].text } title={ email.subject } />
        );
    }

    if (email.html) {
        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={ { __html: email.html } } />;
    }

    if (email.text) {
        return (
            <Typography.Text view="primary-medium" color="primary" weight="medium">
                { email.text }
            </Typography.Text>
        );
    }

    return (
        <Typography.Text view="primary-medium" color="primary" weight="medium">
            No content
        </Typography.Text>
    );
};

export const EmailViewer = ({ email }: Props) => (
    <Padding padding="32px">
        <FlexColumns columns={ 1 } gr={ 32 }>
            <Typography.Title view="small" tag="div" color="primary" weight="bold">
                { email.subject }
            </Typography.Title>

            <Body email={ email } />
        </FlexColumns>
    </Padding>
);
