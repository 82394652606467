import React from 'react';
import {
    Cell, Flex, FlexColumns, Image,
} from '@alphakits/ui/dist';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';
import { format } from 'date-fns';
import { EmailStatus } from 'src/pages/email-service/email-status';
import { getEmailIcons } from 'src/pages/email-service/inbox/columns';
import { getEmailText } from 'src/pages/email-service/outbox/columns';
import { OpenEmail } from 'src/pages/email-service/profile-button';

type Props = {
    email: EmailRO;
};

export const EmailCell: React.FC<Props> = ({ email }) => {
    const rec = email.status === EmailStatusEnum.INBOX ? email.from : email.to;

    return (
        <Flex gap="md">
            <Cell.Base
                size="l"
                addon={ <Image icon={ getEmailIcons(email) } /> }
                title={ (
                    <React.Fragment>
                        <b>{ rec }</b>:{ ' ' }
                        { email.subject.length > 200
                            ? ` ${email.subject?.slice(0, 200)}...`
                            : email.subject }
                    </React.Fragment>
                ) }
                subtitle={ (
                    <FlexColumns columns={ 1 } gr={ 4 }>
                        { getEmailText(email) }
                        <div>
                            { `${format(new Date(email.createdAt), 'dd.MM.yyyy HH:hh')}, ${
                                email.status === EmailStatusEnum.INBOX ? 'Inbox' : 'Outbox'
                            }` }
                        </div>
                    </FlexColumns>
                ) }
            />

            <Flex gap="xs">
                { email.status !== EmailStatusEnum.INBOX && <EmailStatus email={ email } /> }
                <OpenEmail email={ email } />
            </Flex>
        </Flex>
    );
};
