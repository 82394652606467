import React from 'react';
import { OpenS } from '@alphakits/icons';
import { Button } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { namespaceByCountry } from '@escapenavigator/types/dist/constants/namespace-by-country';
import { NowEscapeQuestroomRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-questroom.ro';

export const questroomsColumns = (): TableColumns<NowEscapeQuestroomRO> => [
    {
        header: 'Title',
        accessor: 'title',
        row: {
            image: 'photo',
            subtitle: ({ row }) => row.location?.address || 'Removed location',
        },
    },
    {
        header: 'QUESTROOM_ID',
        accessor: 'id',
        row: {
            title: ({ row }) => <b>{ row.id }</b>,
        },
    },
    {
        header: 'Options',
        accessor: 'slug',
        row: {
            title: ({ row }) => {
                const country = row.slug.split('/')[1];
                const slug = `${row.slug.split('/')[2]}/${row.slug.split('/')[3]}`;

                return (
                    <Button
                        leftAddons={ <OpenS /> }
                        view="outlined"
                        size="xs"
                        target="_blank"
                        href={ `https://escapenavigator.${namespaceByCountry[country]}/escape-rooms/${slug}` }
                    />
                );
            },
        },
    },
];
