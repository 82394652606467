import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CityRO } from '@escapenavigator/types/dist/city/city.ro';

export const columns: TableColumns<CityRO> = [
    {
        header: 'Title',
        accessor: 'title',
        sort: true,
        row: {
            image: 'photo',
        },
    },
    {
        header: 'Slug',
        accessor: 'slug',
        sort: true,
    },
    {
        header: 'In',
        accessor: 'inTitle',
        sort: true,
    },
    {
        header: 'Near',
        accessor: 'nearTitle',
        sort: true,
    },
    {
        header: 'Country',
        sort: true,
        accessor: 'country',
    },
    {
        header: 'State',
        sort: true,
        accessor: 'stateCode',
        row: {
            subtitle: 'stateTitle',
        },
    },
    {
        header: 'Coordinates',
        sort: true,
        accessor: 'coordinates',
        row: {
            title: ({ row }) => JSON.stringify((row.coordinates as any)?.coordinates),
        },
    },
    {
        header: 'Questrooms',
        sort: true,
        accessor: 'questroomsCount',
    },
];
