import React from 'react';
import { CopyButton } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { AgbRO } from '@escapenavigator/types/dist/agb/agb.ro';

export const columns: TableColumns<AgbRO> = [
    {
        header: 'Type',
        accessor: 'type',
    },
    {
        header: 'Country',
        accessor: 'country',
    },

    {
        header: 'Link',
        accessor: 'id',
        row: {
            title: ({ row }) => {
                const link = `https://orders.escapenavigator.com?type=${row.type}&country=${row.country}`;

                return <CopyButton text={ link } fullText={ link } />;
            },
        },
    },
    {
        header: 'Text',
        accessor: 'text',
        row: {
            title: ({ row }) => row.text.slice(0, 50),
        },
    },
];
