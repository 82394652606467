import React, { useEffect } from 'react';
import { Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const Issues: React.FC<DefaultPageProps> = () => {
    const { admins } = useApi();

    const { findAllIssuesData, findAllIssuesFetch, findAllIssuesLoading } = useApiMethod({
        api: admins.findAllIssues,
    });

    useEffect(() => {
        findAllIssuesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ columns() }
                language="en"
                loading={ findAllIssuesLoading }
                records={ findAllIssuesData || [] }
            />
        </React.Fragment>
    );
};
