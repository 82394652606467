import React, { useContext, useEffect, useMemo } from 'react';
import { PlayS } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, Loader, ModalContext,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { AdminProfileRO } from '@escapenavigator/types/dist/profile/admin-profile.ro';
import { format } from 'date-fns';
import { ActionCell } from 'src/components/action-cell';
import { EmailCell } from 'src/components/email-cell';
import { HistoryCell } from 'src/components/history-cell';
import { OrganizationActionModal } from 'src/modals/organization-action';
import { OrganizationActivityModal } from 'src/modals/organization-activity';
import { OrganizationEmail } from 'src/modals/organization-email';
import { useApi } from 'src/providers/api/context';

type Props = {
    profile: AdminProfileRO;
    cb: () => void;
};

export const OrganizationActions: React.FC<Props> = ({ profile, cb }) => {
    const { emails, profiles } = useApi();
    const { openModal } = useContext(ModalContext);

    const { getProfileEmailsData, getProfileEmailsFetch, getProfileEmailsLoading } = useApiMethod({
        api: emails.getProfileEmails,
    });

    const { queryActivityData, queryActivityFetch, queryActivityLoading } = useApiMethod({
        api: profiles.queryActivity,
    });

    useEffect(() => {
        getProfileEmailsFetch(profile.id);
        queryActivityFetch({ where: { profileId: profile.id }, page: 1, limit: 100 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const allActions = useMemo(() => {
        const emails = (getProfileEmailsData || []).map((email) => ({
            createdAt: email.createdAt,
            type: 'email' as const,
            email,
        }));
        const actions = (profile.actions || []).map((action) => ({
            createdAt: action.createdAt,
            type: 'action' as const,
            action,
        }));

        const activities = (queryActivityData?.items || []).map((activity) => ({
            createdAt: activity.createdAt,
            type: 'activity' as const,
            activity,
        }));
        const history = Object.values(
            profile.history.reduce((acc, h) => {
                const time = format(new Date(h.createdAt), 'dd MMMM yyyy, HH:mm');

                acc[time] = acc[time] || {
                    createdAt: h.createdAt,
                    type: 'changes' as const,
                    items: [],
                };

                acc[time].items.unshift(h);

                return acc;
            }, {} as Record<string, { createdAt: Date; type: 'changes'; items: AdminProfileRO['history'] }>),
        );

        return [...emails, ...actions, ...history, ...activities].sort((a, b) =>
            (a.createdAt > b.createdAt ? -1 : 1));
    }, [getProfileEmailsData, profile, queryActivityData]);

    if (getProfileEmailsLoading || queryActivityLoading) return <Loader />;

    return (
        <FlexColumns columns={ 1 } gr={ 16 }>
            <Flex justify="start" gap="sm">
                <Button
                    size="xs"
                    view="outlined"
                    onClick={ () => {
                        openModal(OrganizationActionModal)({
                            profileId: profile.id,
                            prevActionId: 0,
                            cb,
                        });
                    } }
                >
                    New action
                </Button>

                <Button
                    size="xs"
                    view="outlined"
                    onClick={ () => {
                        openModal(OrganizationEmail)({ profile, cb });
                    } }
                >
                    New email
                </Button>
            </Flex>

            { allActions.map((p) => {
                if (p.type === 'email') return <EmailCell email={ p.email } />;

                if (p.type === 'activity') {
                    return (
                        <Flex justify="start" gap="md">
                            Activity: { p.activity.type }
                            <Button
                                leftAddons={ <PlayS /> }
                                onClick={ () =>
                                    openModal(
                                        OrganizationActivityModal,
                                        'full',
                                    )({ sessionUrl: p.activity.link }) }
                                view="outlined"
                                size="xs"
                            />
                        </Flex>
                    );
                }

                if (p.type === 'changes') return <HistoryCell createdAt={ p.createdAt } items={ p.items } />;

                if (p.type === 'action') return <ActionCell cb={ cb } profileId={ profile.id } action={ p.action } />;

                return null;
            }) }
        </FlexColumns>
    );
};
