/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { Loader, ModalContext } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { addMinutes, isBefore } from 'date-fns';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { OrganizationInfoModal } from 'src/modals/organization-info/main';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

const locales = {
    'en-US': enUS,
};

type Event = {
    title: string;
    done: boolean;
    success: boolean;
    start: Date;
    end: Date;
    allDay: boolean;
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const duration = {
    email: 10,
    call: 10,
    presentation: 60,
    check: 5,
};

export const ActionsPage: React.FC<DefaultPageProps> = () => {
    const { profiles } = useApi();
    const { openModal } = useContext(ModalContext);

    const { queryActionsFetch, queryActionsLoading, queryActionsData } = useApiMethod({
        api: profiles.queryActions,
    });

    useEffect(() => {
        queryActionsFetch({ page: 1, where: {}, limit: 1000 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const events = useMemo(() => {
        if (!queryActionsData) return [];

        return queryActionsData.items.map((action) => {
            const date = parse(
                `${action.date} ${action.time || '00:00'}`,
                'yyyy-MM-dd HH:mm',
                new Date(),
            );

            return {
                title: `${action.type}, ${action.profile?.title} ${action.success}`,
                profileId: action.profile?.id,
                success: action.success,
                done: action.done,
                start: date,
                end: addMinutes(date, duration[action.type]),
                allDay: !action.time,
            };
        });
    }, [queryActionsData]);

    if (queryActionsLoading) return <Loader />;

    const getColor = (event: Event) => {
        if (event.success === false) return 'var(--color-bg-thirdly)';
        if (event.success === true) return 'var(--color-bg-positive)';
        if (isBefore(new Date(event.start), new Date()) && !event.done) return 'var(--color-bg-negative)';

        return 'var(--color-bg-accent)';
    };

    return (
        <Calendar
            events={ events }
            onNavigate={ (date, view) => {} }
            onDoubleClickEvent={ (e) => {
                openModal(OrganizationInfoModal, 'm')({ profileId: e.profileId });
            } }
            eventPropGetter={ (event) => ({
                style: {
                    background: getColor(event),
                    fontSize: 14,
                },
            }) }
            onView={ (view) => {} }
            localizer={ localizer }
            startAccessor="start"
            endAccessor="end"
            style={ { height: 'calc(100vh - 100px)' } }
        />
    );
};
