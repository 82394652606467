import React from 'react';
import { UpdateM } from '@alphakits/icons';
import { Button, showError, showSuccess } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { TranslationRO } from '@escapenavigator/types/dist/translation/translation.ro';
import { useApi } from 'src/providers/api/context';

type Props = {
    translation: TranslationRO;
    cb: () => void;
};
export const RefetchButton: React.FC<Props> = ({ cb, translation }) => {
    const { translationsApi } = useApi();

    const { regenerateTranslationLoading, regenerateTranslationFetch } = useApiMethod({
        api: translationsApi.regenerateTranslation,
        successCallback: () => {
            showSuccess('Geneated');
            cb();
        },
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            loading={ regenerateTranslationLoading }
            leftAddons={ <UpdateM /> }
            size="xs"
            view="outlined"
            onClick={ (e) => {
                e.stopPropagation();
                regenerateTranslationFetch(translation.id);
            } }
        />
    );
};
