import React from 'react';
import { FiltersMapper } from '@alphakits/ui/dist/table/types';

import { Admin } from './admin';
import { Country } from './country';
import { FieldCount } from './field-count';
import { NotificationEnd } from './notification-end';
import { NotificationStart } from './notification-start';
import { RegisterEnd } from './register-end';
import { RegisterStart } from './register-start';
import { SimpleRowValue } from './simple-row-value';
import { Status } from './status';

export const mapper =
    () =>
        ({ paramName, paramValue, onDelete }: FiltersMapper) => {
            switch (paramName) {
                case 'registerFrom':
                    return <RegisterStart removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'registerTo':
                    return <RegisterEnd removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'notificationFrom':
                    return (
                        <NotificationStart removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'notificationTo':
                    return (
                        <NotificationEnd removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'admin':
                    return <Admin removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'status':
                    return <Status removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'questroomsCount':
                    return <FieldCount removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'locationsCount':
                    return <FieldCount removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'country':
                    return <Country removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                default:
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ paramValue.toString() }
                        />
                    );
            }
        };
