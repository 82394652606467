/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { Loader, showError, Typography } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { useApi } from 'src/providers/api/context';

type Props = {
    setImage: (image: string) => void;
};

export const FileUploader = ({ setImage }: Props) => {
    const { uploads } = useApi();

    const { uploadImageFetch, uploadImageLoading } = useApiMethod({
        api: uploads.uploadImage,
        successCallback: ({ data: { url } }) => setImage(url),
        errorCallback: ({ message }) => showError(message),
    });

    useEffect(() => {
        const upload = (e: any) => {
            if (e.clipboardData.files.length) {
                if (confirm('add photo?')) {
                    const data = new FormData();

                    data.append('file', e.clipboardData.files[0]);
                    data.append('type', ImageTypeEnum.TRANSLATION);

                    uploadImageFetch({ data, onUploadProgress: () => {} });
                }
            }
        };

        window.addEventListener('paste', upload);

        return () => {
            window.removeEventListener('paste', upload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (uploadImageLoading) return <Loader />;

    return <Typography.Text view="caps">Use ctrl + v to paste image</Typography.Text>;
};
