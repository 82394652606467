import React, { useEffect } from 'react';
import {
    FlexColumns, Loader, Table, ToastPlate,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { NowEscapeProfileRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-profile.ro';
import { useApi } from 'src/providers/api/context';

import { questroomsColumns } from './questrooms-columns';

type Props = {
    profile: NowEscapeProfileRO;
};

export const Questrooms = ({ profile }: Props) => {
    const { nowEscape } = useApi();

    const {
        nowEscapeQuestroomsByProfileData,
        nowEscapeQuestroomsByProfileFetch,
        nowEscapeQuestroomsByProfileLoading,
        nowEscapeQuestroomsByProfileError,
    } = useApiMethod({
        api: nowEscape.nowEscapeQuestroomsByProfile,
    });

    useEffect(() => {
        nowEscapeQuestroomsByProfileFetch(profile.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (nowEscapeQuestroomsByProfileError?.message) {
        return (
            <ToastPlate view="negative" title="Server error">
                { nowEscapeQuestroomsByProfileError?.message }
            </ToastPlate>
        );
    }

    if (nowEscapeQuestroomsByProfileLoading) {
        return <Loader />;
    }

    if (!nowEscapeQuestroomsByProfileData?.length) {
        return <React.Fragment>No escape rooms</React.Fragment>;
    }

    return (
        <FlexColumns columns={ 1 }>
            <ToastPlate view="neutral" title="API">
                GET https://gateway.escapenavigator.com/nowescape/slots/QUESTROOM_ID <br />
                GET https://gateway.escapenavigator.com/nowescape/price/QUESTROOM_ID <br />
                POST https://gateway.escapenavigator.com/nowescape/booking
            </ToastPlate>
            <Table.TableComponent
                columns={ questroomsColumns() }
                hideSearch={ true }
                language="en"
                records={ nowEscapeQuestroomsByProfileData }
            />
        </FlexColumns>
    );
};
