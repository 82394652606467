import { TableColumns } from '@alphakits/ui/dist/table/types';
import { AdminRO } from '@escapenavigator/types/dist/admin/admin.ro';

export const columns: TableColumns<AdminRO> = [
    {
        header: 'Name',
        accessor: 'name',
        row: {
            subtitle: 'email',
        },
    },
    {
        header: 'Access',
        accessor: 'totalAccess',
    },
    {
        header: 'Stats',
        row: {
            title: ({ row: { translationsChanged, translationsRead } }) =>
                ` ${translationsChanged} / ${translationsRead}`,
        },
    },
];
