import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { ProfileCrmRO } from '@escapenavigator/types/dist/profile/profile-crm/profile-crm.ro';
import { validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    record?: ProfileCrmRO;
};

export const ProfileCrmModal = ({
    close, t, record, cb,
}: Props) => {
    const { profiles } = useApi();

    const {
        save,
        error: softError,
        remove,
        removing,
        updating,
    } = useCrudFormRequests({
        saveRequest: record ? profiles.updateProfileCrm : profiles.createOtherCrm,
        removeRequest: profiles.removeProfileCrm,
        recordId: record?.id,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
        removeCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            loading={ updating }
            remove={ remove }
            removing={ removing }
            save={ save }
            softError={ softError }
            recordId={ record?.id }
            validate={ validateByDto(t) }
            title="CRM name"
            close={ close }
            initialValues={ record || { title: '' } }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label="Title"
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            block={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
