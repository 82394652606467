import React from 'react';
import {
    FlexColumns,
    Select,
    Textarea,
    TFunction,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { AgbTypeEnum } from '@escapenavigator/types/dist/agb/agb-type.enum';
import { CreateInsideAgbDto } from '@escapenavigator/types/dist/agb/create-inside-agb.dto';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    recordId?: number;
    t: TFunction;
};

export const AgbModal: React.FC<Props> = ({
    close, t, recordId, cb,
}) => {
    const { agbs } = useApi();

    const { record, loading, error } = useGetOne(+recordId, agbs.getOne);

    const {
        save,
        updating,
        remove,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: recordId ? agbs.update : agbs.create,
        removeRequest: agbs.remove,
        saveCallback: () => cb(),
        recordId,
        close,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            updating={ updating }
            remove={ remove }
            removing={ removing }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            validate={ validateByDto(t) }
            title="Agb"
            close={ close }
            initialValues={ serializeRecord(CreateInsideAgbDto, record) }
        >
            { ({
                values, errors, touched, setFieldValue, handleChange,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        dataTestId="agbType"
                        block={ true }
                        label="Type"
                        options={ enumToOptions(AgbTypeEnum) }
                        selected={ values.type }
                        allowUnselect={ true }
                        error={ touched.type && errors.type }
                        onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Select
                        dataTestId="agbCountry"
                        block={ true }
                        label="Country"
                        options={ enumToOptions(CountriesEnum) }
                        selected={ values.country }
                        error={ touched.country && errors.country }
                        allowUnselect={ true }
                        onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Textarea
                        value={ values.text || '' }
                        onChange={ handleChange('text') }
                        block={ true }
                        maxLength={ 2000 }
                        hint="Google docs link"
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
