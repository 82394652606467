import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Root } from './pages/root';
import { CurrentUserProvider } from './providers/current-user/context';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import './locales/i18n';

function App() {
    return (
        <BrowserRouter>
            <CurrentUserProvider>
                <Root />
            </CurrentUserProvider>
        </BrowserRouter>
    );
}

export default App;
