/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Tag } from '@alphakits/ui';
import { TFunction } from 'i18next';

type Props = {
    row: string;
    value: string;
    removeParam: (taggName: string) => void;
};

export const Status: React.FC<Props> = ({ value, row, removeParam }) => {
    const text = `Status ${value}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
