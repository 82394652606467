import { TableColumns } from '@alphakits/ui/dist/table/types';
import { NowEscapeProfileRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-profile.ro';

export const columns: TableColumns<NowEscapeProfileRO> = [
    {
        header: 'Name',
        accessor: 'title',
        row: {
            subtitle: 'country',
        },
    },
];
