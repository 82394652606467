import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';
import { FiltersQuestroomModal } from 'src/modals/filters/questroom';
import { QuestroomModal } from 'src/modals/questroom';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const QuestroomsPage: React.FC<DefaultPageProps> = () => {
    const { questrooms } = useApi();

    const { openModal } = useContext(ModalContext);

    const onRowClick = (value: QuestroomAdminRO) => {
        openModal(QuestroomModal, 'full')({ questroom: value });
    };

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ columns() }
                onRowClick={ (value) => onRowClick(value) }
                onFiltersClick={ ({ initialValues, submitCallback }) => {
                    openModal(FiltersQuestroomModal)({
                        initialValues,
                        submitCallback,
                    });
                } }
                filtersButtonLabel="Filters"
                searchPlaceholder="Serach by title"
                language="en"
                tagsBuilder={ mapper() }
                promise={ questrooms.paginate }
            />
        </React.Fragment>
    );
};
