export const EMAIL_SERVICE = '/email-service';
export const REGISTRATIONS = '/registrations';
export const AGGREGATORS = '/aggregators';
export const ORGANIZATIONS = '/organizations';
export const DASHBOARD = '/dashboard';
export const ACTIONS = '/actions';
export const NOW_ESCAPE = '/now-escape';
export const VERIFICATIONS = '/verifications';
export const AGBS = '/agbs';
export const QUESTROOMS = '/questrooms';
export const INVOICES = '/invoices';
export const TRANSLATIONS = '/translations';
export const MISSING_TRANSLATIONS = '/missing-translations';
export const ADMINS = '/admins';
export const CERTIFICATE_SERVICE = '/certificate-service';
export const CRMS = '/crms';
export const BILLS = '/bills';
export const CHAT = '/chat';
export const MARKETING = '/marketing';
