import React, { useEffect, useMemo, useState } from 'react';
import { ChevronBackThinM, ChevronForwardThinM } from '@alphakits/icons';
import {
    Box,
    Button,
    Flex,
    FlexColumns,
    formatDate,
    Image,
    Loader,
    Skeleton,
    Table,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { NowEscapeProfileRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-profile.ro';
import { QueryOrderDto } from '@escapenavigator/types/dist/order/query-order.dto';
import {
    addMonths, endOfMonth, isSameMonth, startOfMonth, subMonths,
} from 'date-fns';
import format from 'date-fns/format';
import { useApi } from 'src/providers/api/context';

import { ordersColumns } from './orders-columns';

type Props = {
    profile: NowEscapeProfileRO;
};

export const Orders: React.FC<Props> = ({ profile }) => {
    const { nowEscape } = useApi();

    const columns = React.useMemo(() => ordersColumns(), []);

    const [query, setQuery] = useState({ date: new Date(), view: 'month' as const });

    const {
        nowEscapeOrdersByProfileAndMonthData,
        nowEscapeOrdersByProfileAndMonthFetch,
        nowEscapeOrdersByProfileAndMonthLoading,
        nowEscapeOrdersByProfileAndMonthError,
    } = useApiMethod({
        api: nowEscape.nowEscapeOrdersByProfileAndMonth,
    });

    const canShowNextDate = useMemo(() => {
        if (query.view === 'month') {
            return isSameMonth(query.date, new Date());
        }

        return false;
    }, [query]);

    const where: QueryOrderDto = useMemo(
        () => ({
            profileId: profile.id,
            created: [
                format(startOfMonth(query.date), 'yyyy-MM-dd'),
                format(endOfMonth(query.date), 'yyyy-MM-dd'),
            ],
        }),
        [query, profile],
    );

    useEffect(() => {
        nowEscapeOrdersByProfileAndMonthFetch({
            where,
            limit: 1000,
            page: 1,
            sort: 'createdAt',
            order: 'DESC',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [where]);

    const next = () => {
        if (query.view) {
            setQuery({ ...query, date: addMonths(query.date, 1) });
        }
    };

    const prev = () => {
        if (query.view) {
            setQuery({ ...query, date: subMonths(query.date, 1) });
        }
    };

    if (nowEscapeOrdersByProfileAndMonthError?.message) {
        return (
            <ToastPlate view="negative" title="Server error">
                { nowEscapeOrdersByProfileAndMonthError?.message }
            </ToastPlate>
        );
    }

    if (nowEscapeOrdersByProfileAndMonthLoading) {
        return <Loader />;
    }

    return (
        <FlexColumns columns={ 1 }>
            <Box background="var(--color-bg-secondary)" rounded="xs" padding="l">
                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Flex>
                        <Typography.Title tag="div" view="xsmall" weight="bold" color="primary">
                            { formatDate(query.date, { format: 'LLLL', lang: 'en' }) }
                        </Typography.Title>

                        <Flex gap="sm" justify="end">
                            <Button onClick={ prev } view="ghost">
                                <Image
                                    bgColor="primary"
                                    size="s"
                                    icon={ <ChevronBackThinM /> }
                                    view="circle"
                                />
                            </Button>

                            <Button onClick={ next } view="ghost" disabled={ canShowNextDate }>
                                <Image
                                    size="s"
                                    bgColor={ canShowNextDate ? 'secondary' : 'primary' }
                                    icon={ <ChevronForwardThinM /> }
                                    view="circle"
                                />
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex justify="start" gap="sm">
                        <Skeleton visible={ nowEscapeOrdersByProfileAndMonthLoading }>
                            <Typography.Text view="title" weight="medium" color="secondary">
                                Total count{ ' ' }
                                <Typography.Text view="title" weight="medium" color="primary">
                                    { nowEscapeOrdersByProfileAndMonthData?.meta.totalItems }
                                </Typography.Text>
                            </Typography.Text>
                        </Skeleton>
                    </Flex>
                </FlexColumns>
            </Box>

            <Table.TableComponent
                hideSearch={ true }
                columns={ columns }
                loading={ nowEscapeOrdersByProfileAndMonthLoading }
                language="en"
                records={ nowEscapeOrdersByProfileAndMonthData?.items || [] }
            />
        </FlexColumns>
    );
};
