import React, { ReactNode } from 'react';
import { Flex, FlexColumns, Typography } from '@alphakits/ui/dist';

type Props = {
    rightAddons: ReactNode;
    label: string;
    value: string;
    color?: 'secondary' | 'attention';
};

export const InfoBlockItem: React.FC<Props> = ({
    rightAddons,
    label,
    value,
    color = 'secondary',
}) => (
    <Flex>
        <FlexColumns columns={ 1 } gr={ 4 }>
            <Typography.Text view="caps" color={ color }>
                { label }
            </Typography.Text>
            <Typography.Text view="title">{ value }</Typography.Text>
        </FlexColumns>
        { rightAddons }
    </Flex>
);
