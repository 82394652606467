import React, { useCallback, useEffect, useState } from 'react';
import { showError } from '@alphakits/ui';
import axios from 'axios';

import styles from './index.module.css';

type Props = {
    title: string;
    text: string;
};

export const ViewEmail: React.FC<Props> = ({ text, title }) => {
    const [html, setHtml] = useState();
    const [loading, setLoading] = useState(false);

    const getTemplate = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.post('https://mailer.escapenavigator.com/render', {
                title,
                tag: 'info',
                subscription: false,
                sections: [{ type: 'paragraph', text }],
            });

            setHtml(data);
        } catch (error) {
            showError('Render error');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, title]);

    useEffect(() => {
        getTemplate();
    }, [getTemplate]);

    return (
        <div
            className={ styles.wrap }
            style={ { opacity: loading ? 0.4 : 1 } }
            dangerouslySetInnerHTML={ { __html: html } }
        />
    );
};
