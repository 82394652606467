import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { TabPanel, Tabs } from 'src/components/tabs';
import { NowEscapeProfile } from 'src/modals/now-escape-profile';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { billsColumns, data } from './bills-columns';
import { columns } from './columns';

export const NowEscape: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const { nowEscape } = useApi();

    return (
        <Tabs view="header" defaultActive="Requests">
            <TabPanel name="Requests">
                <Table.TableComponent
                    onRowClick={ (profile) => openModal(NowEscapeProfile, 'm')({ profile }) }
                    columns={ columns }
                    searchPlaceholder="Serach by company"
                    language="en"
                    promise={ nowEscape.nowEscapeProfiles }
                />
            </TabPanel>

            <TabPanel name="Bills">
                <Table.TableComponent
                    columns={ billsColumns }
                    searchPlaceholder="Serach by company"
                    language="en"
                    records={ data }
                />
            </TabPanel>
        </Tabs>
    );
};
