import React, { useCallback, useContext, useEffect } from 'react';
import { OpenS, PlusS, TrashS } from '@alphakits/icons';
import {
    Button,
    Cell,
    Divider,
    Flex,
    FlexColumns,
    Image,
    Link,
    Loader,
    ModalContext,
    Status,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { namespaceByCountry } from '@escapenavigator/types/dist/constants/namespace-by-country';
import { format } from 'date-fns';
import { ModalContainer } from 'src/components/modal-container';
import { TabPanel, Tabs } from 'src/components/tabs';
import { OrganizationModal } from 'src/modals/organization';
import { OrganizationContact } from 'src/modals/organization-contact';
import { useApi } from 'src/providers/api/context';

import { OrganizationActions } from '../actions';

type Props = {
    profileId: number;
};

export const OrganizationInfoModal: React.FC<Props> = ({ profileId }) => {
    const { openModal } = useContext(ModalContext);
    const { admins, profiles } = useApi();

    const {
        getOneData: profile,
        getOneFetch,
        getOneLoading,
    } = useApiMethod({
        api: profiles.getOne,
    });

    const { removeContactFetch, removeContactLoading } = useApiMethod({
        api: profiles.removeContact,
        successCallback: () => getOneFetch(profileId),
    });

    useEffect(() => {
        getOneFetch(profileId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId]);

    const loginAsUser = useCallback(
        async (profileId: number) => {
            try {
                await admins.getProfileToken(profileId);
                window.location.assign(`${process.env.REACT_APP_APP_DOMAIN}`);
            } catch (error) {
                alert('Error');
            }
        },
        [admins],
    );

    if (getOneLoading || !profile || removeContactLoading) {
        return <Loader />;
    }

    const openOrganizationModal = openModal(OrganizationModal);

    // const { profiles } = useApi();

    const profileAgregatorLink = `https://escapenavigator.${
        namespaceByCountry[profile.country]
    }/company/${profile.slug}`;

    return (
        <ModalContainer
            header={ (
                <Flex>
                    <Flex gap="md" justify="start">
                        <Image src="" size="l" view="ellipse" text={ profile.title } />

                        <Flex direction="column" align="start" gap="sm" justify="start">
                            <Typography.Title view="xxsmall" tag="div" weight="bold">
                                { profile.title } ({ profile.id })
                            </Typography.Title>

                            <Flex justify="start" gap="md">
                                <Status view="contrast" color="blue">
                                    { profile.status }
                                </Status>
                                { /* <Typography.Text view="caps" tag="div">
                                    { profile.updateStepDate
                                        ? format(
                                            new Date(profile.updateStepDate),
                                            'dd MMMM yyyy, HH:mm',
                                        )
                                        : '-' }
                                    , { profile.country }
                                </Typography.Text> */ }
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex gap="md">
                        <Button
                            leftAddons={ <OpenS /> }
                            view="outlined"
                            size="xs"
                            onClick={ () => {
                                loginAsUser(profile.id);
                            } }
                        >
                            CRM
                        </Button>

                        <Button
                            leftAddons={ <OpenS /> }
                            view="outlined"
                            size="xs"
                            target="_blank"
                            href={ profileAgregatorLink }
                        >
                            Agregator
                        </Button>

                        <Button
                            view="outlined"
                            size="xs"
                            onClick={ () =>
                                openOrganizationModal({
                                    recordId: profile.id,
                                    cb: () => getOneFetch(profileId),
                                }) }
                        >
                            Edit
                        </Button>
                    </Flex>
                </Flex>
            ) }
        >
            <Tabs defaultActive="Activity">
                <TabPanel name="Activity">
                    <OrganizationActions cb={ () => getOneFetch(profileId) } profile={ profile } />
                </TabPanel>
                <TabPanel name="Information">
                    <FlexColumns columns={ 1 } gr={ 6 } gc={ 6 }>
                        <Typography.Title weight="bold" view="xsmall" tag="div">
                            Info
                        </Typography.Title>

                        <FlexColumns columns={ 3 } gr={ 6 } gc={ 6 }>
                            <Cell.Base title="Legal name" subtitle={ profile.legalTitle } />

                            <Cell.Base title="Prev CRM" subtitle={ profile.crm || '-' } />

                            <Cell.Base title="Tags" subtitle={ profile.tags?.join(', ') || '-' } />

                            <Cell.Base
                                title="Last visit"
                                subtitle={
                                    profile.lastVisit
                                        ? format(new Date(profile.lastVisit), 'dd MMMM yyyy, HH:mm')
                                        : '-'
                                }
                            />

                            <Cell.Base
                                title="Site"
                                subtitle={ (
                                    <Link target="_blank" href={ profile.site }>
                                        { profile.site }
                                    </Link>
                                ) }
                            />

                            <Cell.Base
                                title="Questrooms count"
                                subtitle={ `${profile.questroomsCount} / ${profile.locationsCount} / ${profile.questroomsDeletedCount}` }
                            />

                            <Cell.Base
                                title="Agreement"
                                subtitle={
                                    profile.agreementLink ? (
                                        <Link target="_blank" href={ profile.agreementLink }>
                                            Link
                                        </Link>
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                        </FlexColumns>

                        <Divider size="m" />

                        <Flex gap="md">
                            <Typography.Title weight="bold" view="xsmall" tag="div">
                                Company contacts
                            </Typography.Title>

                            <Button
                                leftAddons={ <PlusS /> }
                                view="outlined"
                                size="xs"
                                onClick={ () =>
                                    openModal(OrganizationContact)({
                                        profileId,
                                        cb: () => getOneFetch(profileId),
                                    }) }
                            >
                                Add contact
                            </Button>
                        </Flex>

                        <FlexColumns columns={ 3 } gr={ 6 } gc={ 6 }>
                            <Cell.Base
                                title="Phone for customers"
                                subtitle={ profile.phoneForCustomers }
                            />

                            <Cell.Base title="Main email" subtitle={ profile.mainEmail } />

                            <Cell.Base
                                title="Other emails"
                                subtitle={ profile.secondaryEmails.toString() || '-' }
                            />
                        </FlexColumns>

                        <Divider size="m" />

                        { profile.contacts.map((contact) => (
                            <React.Fragment>
                                <Flex>
                                    <Typography.Title weight="bold" view="xsmall" tag="div">
                                        { contact.name }
                                    </Typography.Title>

                                    <Button
                                        leftAddons={ <TrashS /> }
                                        view="outlined"
                                        size="xs"
                                        onClick={ () => removeContactFetch(contact.id) }
                                    />
                                </Flex>

                                <FlexColumns columns={ 3 } gr={ 6 } gc={ 6 }>
                                    { contact.phone && (
                                        <Cell.Base title="Phone" subtitle={ contact.phone } />
                                    ) }

                                    { contact.email && (
                                        <Cell.Base title="Email" subtitle={ contact.email } />
                                    ) }

                                    { contact.facebook && (
                                        <Cell.Base title="Facebook" subtitle={ contact.facebook } />
                                    ) }

                                    { contact.linkedin && (
                                        <Cell.Base title="Linked In" subtitle={ contact.linkedin } />
                                    ) }

                                    { contact.xing && (
                                        <Cell.Base title="Xing" subtitle={ contact.xing } />
                                    ) }

                                    { contact.comment && (
                                        <Cell.Base title="Comment" subtitle={ contact.comment } />
                                    ) }
                                </FlexColumns>
                                <Divider size="m" />
                            </React.Fragment>
                        )) }

                        <Typography.Title weight="bold" view="xsmall" tag="div">
                            Comment
                        </Typography.Title>

                        <Cell.Base title={ profile.comment || '-' } />
                    </FlexColumns>
                </TabPanel>

                <TabPanel name="Bills">Bills</TabPanel>
            </Tabs>
        </ModalContainer>
    );
};
