import React from 'react';
import ReactQuill from 'react-quill';
import {
    FlexColumns,
    Input,
    Select,
    TFunction,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateExpertReviewDto } from '@escapenavigator/types/dist/review/create-expert-review.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { useApi } from 'src/providers/api/context';

import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';

type New = {
    questroomId: number;
    questroomTitle: string;
    recordId?: never;
};

type Edit = {
    questroomId?: never;
    questroomTitle?: never;
    recordId: number;
};

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
} & (New | Edit);

export const getOptions = (
    start: number,
    end: number,
    prefix = '',
    step = 1,
): Array<{ key: number; content: string }> => {
    const res = [];

    for (let i = start; i <= end; i += step) {
        res.push({
            key: i,
            content: `${i} ${prefix}`,
        });
    }

    return res;
};

export const NavigatorReviewModal: React.FC<Props> = ({
    close,
    t,
    cb,
    questroomId,
    recordId,
    questroomTitle,
}) => {
    const { expertsReviews } = useApi();

    const { record, loading, error } = useGetOne(recordId, expertsReviews.getOne);

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: recordId ? expertsReviews.update : expertsReviews.create,
        removeRequest: expertsReviews.remove,
        saveCallback: () => cb(),
        recordId,
        close,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            updating={ updating }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            validate={ validateByDto(t) }
            title={ questroomTitle || record.questroom?.title }
            close={ close }
            initialValues={ serializeRecord(
                CreateExpertReviewDto,
                recordId ? { ...record, questroomId: record.questroom?.id } : { questroomId },
            ) }
        >
            { ({
                values, errors, touched, setFieldValue, handleChange,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="contactName"
                        label="Title"
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />

                    <div className={ styles.editor_wrap }>
                        <Typography.Text
                            className={ styles.text }
                            view="primary-small"
                            weight="medium"
                        >
                            Content *
                        </Typography.Text>

                        <ReactQuill
                            value={ values.text || '' }
                            onChange={ (content) => setFieldValue('text', content) }
                            theme="snow"
                        />
                    </div>

                    <Select
                        dataTestId="atmosphere"
                        block={ true }
                        label="Atmosphere"
                        options={ getOptions(3, 10) }
                        selected={ values.atmosphere }
                        error={ touched.atmosphere && errors.atmosphere }
                        onChange={ ({ selected }) => setFieldValue('atmosphere', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Select
                        dataTestId="clean"
                        block={ true }
                        label="Clean"
                        options={ getOptions(3, 10) }
                        selected={ values.clean }
                        error={ touched.clean && errors.clean }
                        onChange={ ({ selected }) => setFieldValue('clean', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Select
                        dataTestId="mobility"
                        block={ true }
                        label="Mobility"
                        options={ getOptions(3, 10) }
                        selected={ values.mobility }
                        error={ touched.mobility && errors.mobility }
                        onChange={ ({ selected }) => setFieldValue('mobility', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Select
                        dataTestId="plot"
                        block={ true }
                        label="Plot"
                        options={ getOptions(3, 10) }
                        selected={ values.plot }
                        error={ touched.plot && errors.plot }
                        onChange={ ({ selected }) => setFieldValue('plot', selected?.key) }
                        optionsListWidth="field"
                    />

                    <Select
                        dataTestId="puzzle"
                        block={ true }
                        label="Puzzle"
                        options={ getOptions(3, 10) }
                        selected={ values.puzzle }
                        error={ touched.puzzle && errors.puzzle }
                        onChange={ ({ selected }) => setFieldValue('puzzle', selected?.key) }
                        optionsListWidth="field"
                    />
                    <Select
                        dataTestId="team"
                        block={ true }
                        label="Team"
                        options={ getOptions(3, 10) }
                        selected={ values.team }
                        error={ touched.puzzle && errors.team }
                        onChange={ ({ selected }) => setFieldValue('team', selected?.key) }
                        optionsListWidth="field"
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
