import { formatDate } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { EmailCampaignRO } from '@escapenavigator/types/dist/profile/email/email-campaign.ro';

export const Columns = (): TableColumns<EmailCampaignRO> => [
    {
        header: 'Title',
        accessor: 'title',
        row: {
            subtitle: ({ row }) => formatDate(row.createdAt, { format: 'dd MMMM, yyyy' }),
        },
    },
    {
        header: 'Total',
        accessor: 'total',
    },
    {
        header: 'Clicks',
        accessor: 'click',
    },
    {
        header: 'Opens',
        accessor: 'opened',
    },
    {
        header: 'Pending',
        accessor: 'pending',
    },
    {
        header: 'Send',
        accessor: 'sent',
    },
    {
        header: 'Rejected',
        accessor: 'rejected',
    },
    {
        header: 'Spam',
        accessor: 'spam',
    },
    {
        header: 'Bounced',
        accessor: 'bounced',
    },
    {
        header: 'Soft Bounced',
        accessor: 'soft_bounced',
    },
];
