import React, { useEffect } from 'react';
import { OpenS } from '@alphakits/icons';
import {
    Button,
    Divider,
    FlexColumns,
    Input,
    Select,
    showError,
    SuperListHeader,
    Switch,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateProfileDto } from '@escapenavigator/types/dist/profile/create-profile.dto';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { ProfileTagEnum } from '@escapenavigator/types/dist/profile/enum/profile-tag.enum';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { EmailListBuilder } from 'src/components/email-list-builder';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Id =
    | {
        recordId: number;
        requestId?: never;
    }
    | {
        requestId: number;
        recordId?: never;
    };

type OrganizationModalProps = {
    close: () => void;
    cb: () => void;
    t: TFunction;
} & Id;

export const OrganizationModal = ({
    close,
    t,
    recordId,
    requestId,
    cb,
}: OrganizationModalProps) => {
    const { profiles } = useApi();

    const {
        current: { totalAccess },
    } = useCurrentUser();

    const { getAllCrmData, getAllCrmFetch, getAllCrmLoading } = useApiMethod({
        api: profiles.getAllCrm,
        errorCallback: ({ message }) => showError(message),
    });

    useEffect(() => {
        getAllCrmFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { record, loading, error } = useGetOne(recordId, profiles.getOne);

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: recordId ? profiles.update : profiles.create,
        removeRequest: profiles.remove,
        recordId,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            loading={ loading || getAllCrmLoading }
            updating={ updating || removing }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            remove={ totalAccess && remove }
            validate={ validateByDto(t) }
            title={
                record?.id
                    ? `${record.questroomsCount ? '' : '[CLOSED] '} Profile №${record?.id}`
                    : `New profile by request ${requestId}`
            }
            close={ close }
            initialValues={ serializeRecord(
                CreateProfileDto,
                recordId ? record : { registrationRequestId: requestId, tags: [] },
            ) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        { record.id ? (
                            <React.Fragment>
                                <div>
                                    { record.site && (
                                        <Button
                                            leftAddons={ <OpenS /> }
                                            view="link"
                                            target="_blank"
                                            size="xs"
                                            href={ record.site }
                                            onClick={ (e) => e.stopPropagation() }
                                        >
                                            { record.site }
                                        </Button>
                                    ) }
                                </div>

                                <Select
                                    dataTestId="crm"
                                    selected={ values.crm }
                                    label="CRM"
                                    onChange={ ({ selected }) => setFieldValue('crm', selected?.key) }
                                    options={ getAllCrmData
                                        ?.sort((a, b) => b.profiles - a.profiles)
                                        .map(({ title }) => ({
                                            key: title,
                                            content: title,
                                        })) }
                                    error={ touched.crm && errors.crm }
                                    block={ true }
                                />
                            </React.Fragment>
                        ) : (
                            <Select
                                selected={ values.country }
                                label="Country"
                                onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                                options={ enumToOptions(CountriesEnum) }
                                error={ touched.country && errors.country }
                                block={ true }
                            />
                        ) }

                        <Select
                            selected={ values.status }
                            label="Status"
                            onChange={ ({ selected }) => setFieldValue('status', selected?.key) }
                            options={ enumToOptions(ProfileStatusEnum) }
                            error={ touched.status && errors.status }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Title"
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Website"
                            value={ values.site }
                            onChange={ handleChange('site') }
                            error={ touched.site && errors.site }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Phone for customers"
                            value={ values.phoneForCustomers }
                            onChange={ handleChange('phoneForCustomers') }
                            error={ touched.phoneForCustomers && errors.phoneForCustomers }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            label="Main email"
                            hint="Это email, который указан для связи с квестами компании"
                            value={ values.mainEmail }
                            onChange={ handleChange('mainEmail') }
                            error={ touched.mainEmail && errors.mainEmail }
                            required={ true }
                            block={ true }
                        />

                        <EmailListBuilder
                            error={ touched.secondaryEmails && (errors.secondaryEmails as string) }
                            emailList={ values.secondaryEmails || [] }
                            onChange={ (emails) => setFieldValue('secondaryEmails', emails) }
                        />

                        <Divider size="m" />

                        <SuperListHeader text="Imprint info" />

                        <Select
                            dataTestId="language"
                            selected={ values.language }
                            label="Language"
                            onChange={ ({ selected }) => setFieldValue('language', selected?.key) }
                            options={ enumToOptions(Languages) }
                            error={ touched.language && errors.language }
                            block={ true }
                        />

                        <Input
                            label="Legal Title"
                            value={ values.legalTitle }
                            onChange={ handleChange('legalTitle') }
                            error={ touched.legalTitle && (errors.legalTitle as string) }
                            required={ true }
                            block={ true }
                        />

                        <Divider size="m" />

                        <SuperListHeader text="Social" />

                        <Input
                            dataTestId="instagram"
                            label="Instagram"
                            value={ values.instagram }
                            onChange={ handleChange('instagram') }
                            block={ true }
                        />

                        <Input
                            dataTestId="facebook"
                            label="Facebook"
                            value={ values.facebook }
                            onChange={ handleChange('facebook') }
                            block={ true }
                        />

                        <Input
                            label="Last email id"
                            value={ `${values.lastEmailSendId}` }
                            onChange={ handleChange('lastEmailSendId') }
                            block={ true }
                        />

                        <Divider size="m" />

                        <Select
                            selected={ values.tags }
                            label="Tags"
                            onChange={ ({ selectedMultiple }) =>
                                setFieldValue(
                                    'tags',
                                    selectedMultiple.map((s) => s.key),
                                ) }
                            options={ enumToOptions(ProfileTagEnum) }
                            error={ touched.tags && (errors.tags as string) }
                            block={ true }
                            multiple={ true }
                        />

                        <Select
                            selected={ values.partnerProgram }
                            label="Partner Program (do not change)"
                            onChange={ ({ selected }) =>
                                setFieldValue('partnerProgram', selected?.key) }
                            options={ enumToOptions(PartnerProgramEnum) }
                            error={ touched.partnerProgram && errors.partnerProgram }
                            block={ true }
                        />

                        <Select
                            selected={ values.crmVerification }
                            label="Crm verification (do not change)"
                            onChange={ ({ selected }) =>
                                setFieldValue('crmVerification', selected?.key) }
                            options={ enumToOptions(CrmVerificationEnum) }
                            error={ touched.crmVerification && errors.crmVerification }
                            block={ true }
                        />

                        <Textarea
                            dataTestId="comment"
                            label="Comment"
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            block={ true }
                        />

                        <Switch
                            label="NowEscape integration"
                            onChange={ (_, value) => {
                                setFieldValue('nowEscape', value.checked);
                            } }
                            checked={ values.nowEscape }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
