import React from 'react';
import { Button, showError, showSuccess } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { AggregatorIssueRO } from '@escapenavigator/types/dist/admin/aggregator-issue/aggregator-issue.ro';
import { useApi } from 'src/providers/api/context';

const Remove = ({ id }: { id: number }) => {
    const { admins } = useApi();

    const { removeIssuesFetch, removeIssuesLoading } = useApiMethod({
        api: admins.removeIssues,
        successCallback: () => showSuccess('Removed'),
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            onClick={ (e) => {
                e.stopPropagation();

                return removeIssuesFetch({ id });
            } }
            view="outlined"
            loading={ removeIssuesLoading }
        />
    );
};

export const columns = (): TableColumns<AggregatorIssueRO> => [
    {
        header: 'Type',
        accessor: 'type',
        sort: true,
        row: {
            subtitle: 'url',
        },
    },
    {
        header: 'Text',
        accessor: 'new',
    },
    {
        header: 'Old value',
        accessor: 'old',
    },
    {
        header: '',
        row: {
            title: ({ row }) => <Remove id={ row.id } />,
        },
    },
];
