import React from 'react';
import { PencilM } from '@alphakits/icons';
import { FlexColumns, Sidepanel, Typography } from '@alphakits/ui';
import { allLanguagesByNamespace } from '@escapenavigator/types/dist/constants/all-languages-by-namespace';
import { languageByCountry } from '@escapenavigator/types/dist/constants/language-by-country';
import { namespaceByCountry } from '@escapenavigator/types/dist/constants/namespace-by-country';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';
import { TabPanel, Tabs } from 'src/components/tabs';

import { InfoBlockItem } from './cell';

type Props = {
    questroom: QuestroomAdminRO;
};

export const QuestroomModal = ({ questroom }: Props) => {
    const techLocales =
        questroom.techlocales ||
        allLanguagesByNamespace[namespaceByCountry[questroom.profile.country]]
            .filter((l) => l !== languageByCountry[questroom.profile.country])
            .map((l) => ({
                id: 0,
                language: l,
                title: '-',
                legend: '-',
                teaser: '-',
                importantInfo: '-',
            }));

    const hasError = (text, row) => text === '' || text === '-' || questroom[row] === text;

    return (
        <Sidepanel title={ questroom.title }>
            <Tabs defaultActive="Main">
                <TabPanel name="Main">
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        <FlexColumns columns={ 1 } gr={ 4 }>
                            <Typography.Title view="small" weight="bold" tag="div">
                                Title
                            </Typography.Title>
                            <InfoBlockItem
                                rightAddons={ <PencilM /> }
                                label={ `Original ${languageByCountry[questroom.profile.country]}` }
                                value={ questroom.title }
                            />

                            { questroom.locales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Custom  ${l.language}` }
                                    color={ hasError(l.title, 'title') ? 'attention' : 'secondary' }
                                    value={ l.title }
                                />
                            )) }

                            { techLocales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    color={ hasError(l.title, 'title') ? 'attention' : 'secondary' }
                                    label={ `Tech  ${l.language}` }
                                    value={ l.title }
                                />
                            )) }
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 4 }>
                            <Typography.Title view="small" weight="bold" tag="div">
                                Legend
                            </Typography.Title>

                            <InfoBlockItem
                                rightAddons={ <PencilM /> }
                                label={ `Original ${languageByCountry[questroom.profile.country]}` }
                                value={ questroom.legend }
                            />

                            { questroom.locales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Custom ${l.language}` }
                                    color={ hasError(l.legend, 'legend') ? 'attention' : 'secondary' }
                                    value={ l.legend }
                                />
                            )) }

                            { techLocales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Tech  ${l.language}` }
                                    color={ hasError(l.legend, 'legend') ? 'attention' : 'secondary' }
                                    value={ l.legend }
                                />
                            )) }
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 4 }>
                            <Typography.Title view="small" weight="bold" tag="div">
                                Teaser
                            </Typography.Title>

                            <InfoBlockItem
                                rightAddons={ <PencilM /> }
                                label={ `Original ${languageByCountry[questroom.profile.country]}` }
                                value={ questroom.teaser }
                            />

                            { questroom.locales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Custom ${l.language}` }
                                    color={ hasError(l.teaser, 'teaser') ? 'attention' : 'secondary' }
                                    value={ l.teaser }
                                />
                            )) }

                            { techLocales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Tech ${l.language}` }
                                    color={ hasError(l.teaser, 'teaser') ? 'attention' : 'secondary' }
                                    value={ l.teaser }
                                />
                            )) }
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 4 }>
                            <Typography.Title view="small" weight="bold" tag="div">
                                Important info
                            </Typography.Title>

                            <InfoBlockItem
                                rightAddons={ <PencilM /> }
                                label={ `Original ${languageByCountry[questroom.profile.country]}` }
                                value={ questroom.importantInfo }
                            />

                            { questroom.locales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Custom ${l.language}` }
                                    value={ l.importantInfo }
                                />
                            )) }

                            { techLocales?.map((l) => (
                                <InfoBlockItem
                                    rightAddons={ <PencilM /> }
                                    label={ `Tech ${l.language}` }
                                    value={ l.importantInfo }
                                />
                            )) }
                        </FlexColumns>
                    </FlexColumns>
                </TabPanel>
                <TabPanel name="Avards">Awards</TabPanel>
            </Tabs>
        </Sidepanel>
    );
};
