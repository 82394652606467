export const TRANSLATIONS_RU = {
    Required: 'Обязательно для заполнения',
    'Form has errors': 'Исправьте ошибки в форме ниже',
    validationErrors: {
        'must be an URL address': 'Укажите верный адрес сайта',
        'must be a valid phone number': 'Укажите телефон в международном формате',
        'must be an email': 'Укажите верный email адрес',
        'must be a positive number': 'Укажите целое число',
        'must be an integer number': 'Укажите целое число',
    },
    save: 'Save',
    cancel: 'Cancel',
    menu: {
        '/': 'Wellcome to Escape Navigator',
        '/registrations': 'Registration requests',
        '/email-service': 'Email service',
        '/dashboard': 'Dashboard',
        '/aggregators': 'Agregators',
        '/players': 'Players',
        '/now-escape': 'Now Escape',
        '/actions': 'Actions',
        '/organizations': 'Profiles',
        '/verifications': 'Profile verification',
        '/marketing': 'Marketing',
        '/admins': 'Admins',
        '/translations': 'Translations',
        '/articles': 'Articles',
        '/cities': 'Cities',
        '/commissions': 'Commissions',
        '/agbs': 'Agbs',
        '/crms': 'CRMS',
        '/reviews': 'Reviews',
        '/questrooms': 'Questrooms',
        '/missing-translations': 'Missing Translations',
        '/bills': 'Bills',
        '/aggregator-issues': 'Aggregator issues',
        '/certificate-service': 'Certificate service',
    },
};
