import React, { useContext } from 'react';
import { EditS } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, InfoBlockItem, ModalContext, Status,
} from '@alphakits/ui/dist';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { TranslationStatusEnum } from '@escapenavigator/types/dist/translation/enum/translation-status.enum';
import { TranslationRO } from '@escapenavigator/types/dist/translation/translation.ro';
import { TranslationLocaleModal } from 'src/modals/translation-locale';
import { useCurrentUser } from 'src/providers/current-user/context';

const statuses = (status: TranslationStatusEnum, text: string) => {
    switch (status) {
        case TranslationStatusEnum.NEED_CHECK:
            return (
                <Status color="purple" view="contrast">
                    { text }
                </Status>
            );

        case TranslationStatusEnum.WAITING:
            return (
                <Status color="orange" view="contrast">
                    { text }
                </Status>
            );

        case TranslationStatusEnum.READY:
            return (
                <Status color="green" view="contrast">
                    { text }
                </Status>
            );

        case TranslationStatusEnum.SKIPPER:
            return (
                <Status color="grey" view="contrast">
                    { text }
                </Status>
            );

        default:
            return (
                <Status color="red" view="contrast">
                    { text }
                </Status>
            );
    }
};

type Props = {
    translation: TranslationRO;
    cb: () => void;
};

export const TranslationRow: React.FC<Props> = ({ translation, cb }) => {
    const { openModal } = useContext(ModalContext);
    const { current } = useCurrentUser();

    const openLocaleModal = (locale: TranslationRO['translations'][0]) =>
        openModal(TranslationLocaleModal)({ locale, cb, translation });

    return (
        <FlexColumns columns={ 1 } gr={ 0 } gc={ 0 }>
            { translation.translations
                .sort((a, b) => (a.locale > b.locale ? 1 : -1))
                .map(
                    (t) =>
                        (current.totalAccess || t.locale !== Languages.RU) && (
                            <InfoBlockItem
                                key={ t.id }
                                view="start"
                                label={ (
                                    <Flex gap="sm">
                                        <div>{ statuses(t.status, t.locale.toUpperCase()) }</div>

                                        <div style={ { textDecoration: 'underline !important' } }>
                                            <Button
                                                view="ghost"
                                                style={ { textAlign: 'left' } }
                                                rightAddons={ <EditS /> }
                                                onClick={ (e) => {
                                                    e.stopPropagation();
                                                    openLocaleModal(t);
                                                } }
                                            >
                                                { t.text }
                                            </Button>
                                        </div>
                                    </Flex>
                                ) }
                            />
                        ),
                ) }
        </FlexColumns>
    );
};
