import React from 'react';
import {
    FlexColumns,
    ImageUploader,
    Input,
    Select,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { UpdateCityDto } from '@escapenavigator/types/dist/city/update-city.dto';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    recordId: number;
    t: TFunction;
};

export const CityModal = ({
    close, t, recordId, cb,
}: Props) => {
    const { cities, uploads } = useApi();

    const { record, loading, error } = useGetOne(recordId, cities.getOne);

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: cities.update,
        removeRequest: cities.remove,
        recordId,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            updating={ updating || removing }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            remove={ remove }
            validate={ validateByDto(t) }
            title={ record?.title }
            close={ close }
            initialValues={ serializeRecord(UpdateCityDto, record) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Select
                            dataTestId="country"
                            selected={ values.country }
                            label="Country"
                            onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                            options={ enumToOptions(CountriesEnum) }
                            error={ touched.country && errors.country }
                            required={ true }
                            block={ true }
                        />

                        <Input
                            dataTestId="title"
                            label="Title"
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                            block={ true }
                        />

                        <ImageUploader
                            title="Poster *"
                            value={ values.photo ? [values.photo] : [] }
                            onUpdate={ (value) => {
                                setFieldValue('photo', value[0]);
                            } }
                            multiple={ false }
                            error={ touched.photo && errors.photo }
                            type={ ImageTypeEnum.POSTER }
                            uploadRequest={ uploads.uploadImage }
                        />

                        { JSON.stringify((record as any).locales, null, 4) }
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
