import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateModal } from 'src/modals/certificate';
import { useApi } from 'src/providers/api/context';

import { CertificateColumns } from './columns';

export const Templates: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const { certificates } = useApi();
    const { getAllInsideData, getAllInsideLoading, getAllInsideFetch } = useApiMethod({
        api: certificates.getAllInside,
    });

    const columns = useMemo(() => CertificateColumns({ t }), [t]);

    const openCashboxModal = openModal(CertificateModal);

    useEffect(() => {
        getAllInsideFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Table.TableComponent
            columns={ columns }
            onRowClick={ (record) =>
                openCashboxModal({ record, cb: () => getAllInsideFetch(undefined) }) }
            onCreateClick={ () => openCashboxModal({ cb: () => getAllInsideFetch(undefined) }) }
            records={ getAllInsideData || [] }
            loading={ getAllInsideLoading }
            language="en"
        />
    );
};
