import React from 'react';
import { TabPanel, Tabs } from 'src/components/tabs';
import { DefaultPageProps } from 'src/types';

import { Inbox } from './inbox';
import { Outbox } from './outbox';

export const EmailService: React.FC<DefaultPageProps> = () => (
    <Tabs view="header" defaultActive="Inbox">
        <TabPanel name="Inbox">
            <Inbox />
        </TabPanel>

        <TabPanel name="Outbox">
            <Outbox />
        </TabPanel>
    </Tabs>
);
