import React from 'react';
import {
    AllertMarkS, ChecklistM, CheckmarkS, TimerS,
} from '@alphakits/icons';
import {
    Button, formatDate, Image, Status, StatusProps,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { InvoiceStatusEnum } from '@escapenavigator/types/dist/profile/enum/invoice-status.enum';
import { InvoiceRO } from '@escapenavigator/types/dist/profile/invoice.ro';

// const getStatus = (invoice: InvoiceRO, t: TFunction) => {
//     const colors: Record<InvoiceStatusEnum, StatusProps['color']> = {
//         [InvoiceStatusEnum.DRAFT]: 'grey',
//         [InvoiceStatusEnum.OPEN]: 'orange',
//         [InvoiceStatusEnum.PAID]: 'green',
//         [InvoiceStatusEnum.UNCOLLECTIBLE]: 'grey',
//         [InvoiceStatusEnum.VOID]: 'grey',
//     };

//     const icons: Record<InvoiceStatusEnum, React.ReactNode> = {
//         [InvoiceStatusEnum.DRAFT]: <AllertMarkS />,
//         [InvoiceStatusEnum.OPEN]: <TimerS />,
//         [InvoiceStatusEnum.PAID]: <CheckmarkS />,
//         [InvoiceStatusEnum.UNCOLLECTIBLE]: <AllertMarkS />,
//         [InvoiceStatusEnum.VOID]: <AllertMarkS />,
//     };

//     const titles: Record<InvoiceRO['status'], string> = {
//         [InvoiceStatusEnum.DRAFT]: t('invoiceStatus.draft'),
//         [InvoiceStatusEnum.OPEN]: t('invoiceStatus.open'),
//         [InvoiceStatusEnum.PAID]: t('invoiceStatus.paid'),
//         [InvoiceStatusEnum.VOID]: t('invoiceStatus.void'),
//         [InvoiceStatusEnum.UNCOLLECTIBLE]: t('invoiceStatus.uncollectible'),
//     };

//     return {
//         color: colors[invoice.status],
//         icon: icons[invoice.status],
//         title: titles[invoice.status],
//     };
// };

const getStatus = (invoice: InvoiceRO) => {
    const colors: Record<InvoiceStatusEnum, StatusProps['color']> = {
        [InvoiceStatusEnum.DRAFT]: 'grey',
        [InvoiceStatusEnum.OPEN]: 'orange',
        [InvoiceStatusEnum.PAID]: 'green',
        [InvoiceStatusEnum.UNCOLLECTIBLE]: 'grey',
        [InvoiceStatusEnum.VOID]: 'grey',
    };

    const icons: Record<InvoiceStatusEnum, React.ReactNode> = {
        [InvoiceStatusEnum.DRAFT]: <AllertMarkS />,
        [InvoiceStatusEnum.OPEN]: <TimerS />,
        [InvoiceStatusEnum.PAID]: <CheckmarkS />,
        [InvoiceStatusEnum.UNCOLLECTIBLE]: <AllertMarkS />,
        [InvoiceStatusEnum.VOID]: <AllertMarkS />,
    };

    return {
        color: colors[invoice.status],
        icon: icons[invoice.status],
        title: invoice.status.toUpperCase(),
    };
};

export const Columns = (): TableColumns<InvoiceRO> => [
    {
        header: 'Title',
        accessor: 'title',
        row: {
            subtitle: ({ row }) => formatDate(row.createdAt, { format: 'dd MMMM, yyyy' }),
            icon: () => <Image view="ellipse" icon={ <ChecklistM /> } />,
        },
    },
    {
        header: 'Status',
        accessor: 'status',
        row: {
            title: ({ row }) => {
                const { title, color, icon } = getStatus(row);

                return (
                    <Status color={ color } view="soft" upperCase={ true }>
                        { icon }
                        { title }
                    </Status>
                );
            },
        },
    },
    {
        header: 'Amount',
        accessor: 'amount',
        row: {
            title: ({ row }) => `${row.amount / 100} ${row.currency}`,
        },
    },
    {
        header: '',
        accessor: 'status',
        align: 'right',
        row: {
            title: ({ row }) =>
                (row.status === InvoiceStatusEnum.OPEN || row.status === InvoiceStatusEnum.PAID) &&
                row.invoiceLink && (
                    <Button
                        href={ row.invoiceLink }
                        view={ row.status === InvoiceStatusEnum.OPEN ? 'primary' : 'outlined' }
                        target="_blank"
                        size="xs"
                    >
                        { row.status === InvoiceStatusEnum.OPEN ? 'Pay' : 'Download' }
                    </Button>
                ),
        },
    },
];
