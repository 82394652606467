/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { TrashM } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, IconButton, Input, ListAllert,
} from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    emailList: string[];
    onChange: (emails: string[]) => void;
    error?: string;
};

export const EmailListBuilder: React.FC<Props> = ({ emailList = [], onChange, error }) => {
    const [emails, setEmails] = useState(emailList);

    useEffect(() => {
        onChange(emails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emails]);

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            { emails.map((email, index) => (
                <Flex key={ index }>
                    <Input
                        label="Secondary email"
                        value={ email }
                        onChange={ (e, { value }) => {
                            emails[index] = value;
                            setEmails([...emails]);
                        } }
                        required={ true }
                        block={ true }
                    />

                    <IconButton
                        icon={ TrashM }
                        onClick={ () => {
                            emails.splice(index, 1);
                            setEmails([...emails]);
                        } }
                    />
                </Flex>
            )) }

            <Button onClick={ () => setEmails([...emails, '']) } size="xs" view="outlined">
                Add email
            </Button>

            { !!error && <ListAllert className={ styles.allert } text={ error } view="negative" /> }
        </FlexColumns>
    );
};
