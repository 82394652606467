import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ExpertReviewRO } from '@escapenavigator/types/dist/review/expert-review.ro';

export const columns: TableColumns<ExpertReviewRO> = [
    {
        header: 'Title',
        accessor: 'title',
        row: {
            image: ({ row }) => row.questroom?.photo,
            subtitle: ({ row }) => row.questroom?.title,
        },
    },
];
