import React from 'react';
import {
    FlexColumns, Textarea, Typography, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { EditAllTranslationLoacalesDto } from '@escapenavigator/types/dist/translation/edit-all-translation-loacales.dto';
import { TranslationRO } from '@escapenavigator/types/dist/translation/translation.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { FileUploader } from 'src/components/file-uploader';
import { Gallery } from 'src/components/gallery';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    translation: TranslationRO;
    cb: () => void;
    t: TFunction;
};

export const TranslationUpdateModal = ({
    close, t, translation, cb,
}: Props) => {
    const { translationsApi } = useApi();

    const {
        save,
        updating,
        removing,
        remove,
        error: softError,
    } = useCrudFormRequests({
        recordId: translation.id,
        saveRequest: translationsApi.updateContext,
        saveCallback: cb,
        removeRequest: translationsApi.removeTranslation,
        close,
    });

    return (
        <RestForm
            t={ t }
            updating={ updating }
            removing={ removing }
            remove={ remove }
            recordId={ translation.id }
            softError={ softError }
            save={ save }
            validate={ validateByDto(t) }
            title={ translation.row }
            close={ close }
            initialValues={ serializeRecord(EditAllTranslationLoacalesDto, translation) }
        >
            { ({ values, handleChange, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Typography.Text view="title">
                        { translation.translations.find((t) => t.locale === Languages.RU)?.text }
                    </Typography.Text>

                    <Textarea
                        label="Context"
                        value={ values.context }
                        maxHeight={ 1500 }
                        onChange={ handleChange('context') }
                        block={ true }
                    />

                    { values.photo && <Gallery src={ values.photo } /> }

                    <FileUploader setImage={ (img) => setFieldValue('photo', img) } />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
