import React from 'react';
import { NextS } from '@alphakits/icons';
import {
    Box, Flex, FlexColumns, Status, StatusProps, Typography,
} from '@alphakits/ui/dist';
import { AdminProfileRO } from '@escapenavigator/types/dist/profile/admin-profile.ro';
import { format } from 'date-fns';

import styles from './index.module.css';

type Props = {
    createdAt: Date;
    items: AdminProfileRO['history'];
};

const titles = {
    partnerProgram: 'Partner program',
    subscriptionType: 'Package',
    status: 'Verification',
    subscriptionStatus: 'Subscription',
};

const colors: Record<string, StatusProps['color']> = {
    canceled: 'red',
    active: 'green',
    trialing: 'green',
    pro: 'green',
    free: 'green',
    verified: 'green',
    requested: 'orange',
    paused: 'orange',
};

export const HistoryCell: React.FC<Props> = ({ items, createdAt }) => (
    <Box border={ true } rounded="md" padding="md">
        <FlexColumns columns={ 1 } gr={ 8 }>
            <Typography.Text view="title" weight="bold">
                { format(new Date(createdAt), 'dd MMMM yyyy, HH:mm') }
            </Typography.Text>

            { items.map((item) => (
                <Flex justify="start" gap="md">
                    <Typography.Text view="title" className={ styles.cell }>
                        { titles[item.row] || item.row }
                    </Typography.Text>

                    <Status view="contrast" color={ colors[item.oldValue] || 'grey' }>
                        { item.oldValue }
                    </Status>

                    <NextS />

                    <Status view="contrast" color={ colors[item.newValue] || 'grey' }>
                        { item.newValue }
                    </Status>
                </Flex>
            )) }
        </FlexColumns>
    </Box>
);
