/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@alphakits/ui/dist';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { useApi } from 'src/providers/api/context';

import { CertificatesColumns } from './columns';

export const NavigatorCertificates: React.FC = () => {
    const { certificatesales } = useApi();
    // const { openModal } = useContext(ModalContext);
    // const openFilters = openModal(CertificatesFiltersModal);
    // const openNewCertificatesale = openModal(CertificatesaleNewModal);
    // const openCertificatesale = openModal(CertificatesaleModal, 'm', true);

    const columns = React.useMemo(
        () => CertificatesColumns({ currency: ProfileCurrencyEnum.EUR }),
        [],
    );

    return (
        <React.Fragment>
            <Table.TableComponent
                // filtersButtonLabel={ t('filters.label') }
                // tagsBuilder={ mapper(t) }
                // onFiltersClick={ ({ submitCallback, initialValues }) =>
                //     openFilters({ apply: submitCallback, initialValues: initialValues.where }) }
                // onRowClick={ (row, successCallback) =>
                //     openCertificatesale({ certificatesaleId: row.id, successCallback }) }
                // onCreateClick={ (successCallback) =>
                //     openNewCertificatesale({ successCallback, openModal }) }
                columns={ columns }
                searchPlaceholder="Search"
                promise={ (params) =>
                    certificatesales.queryInside({
                        ...params,
                        where: { ...params.where, source: SourceEnum.AGREGATOR },
                    }) }
                language="en"
            />
        </React.Fragment>
    );
};
