/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useContext } from 'react';
import {
    EyeS, MessageS, SearchS, TrashS, UpdateS,
} from '@alphakits/icons';
import {
    Button, Flex, ModalContext, showError, showSuccess,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { EmailRO } from '@escapenavigator/types/dist/emails/email.ro';
import { EmailStatusEnum } from '@escapenavigator/types/dist/emails/enum/email.status-enum';
import { EmailThread } from 'src/modals/email-thread';
import { EmailViewer } from 'src/modals/email-viewer';
import { OrganizationInfoModal } from 'src/modals/organization-info/main';
import { useApi } from 'src/providers/api/context';

const OpenThread = ({ email }: { email: string }) => {
    const { openModal } = useContext(ModalContext);

    const openOrganizationModal = openModal(EmailThread, 'm');

    return (
        <Button
            size="xs"
            leftAddons={ <MessageS /> }
            onClick={ () => openOrganizationModal({ email }) }
        />
    );
};

const OpenProfile = ({ profile: { id, title } }: { profile: { title: string; id: number } }) => {
    const { openModal } = useContext(ModalContext);

    const openOrganizationModal = openModal(OrganizationInfoModal, 'm');

    return (
        <Button size="xs" onClick={ () => openOrganizationModal({ profileId: id }) }>
            { title }
        </Button>
    );
};

export const OpenEmail = ({ email }: { email: EmailRO }) => {
    const { openModal } = useContext(ModalContext);

    return (
        <Button
            size="xs"
            leftAddons={ <EyeS /> }
            onClick={ () => openModal(EmailViewer, 'm')({ email }) }
        />
    );
};

const Remove = ({ identificator }: { identificator: string }) => {
    const { emails } = useApi();
    const { removeFetch, removeLoading } = useApiMethod({
        api: emails.remove,
        successCallback: () => showSuccess('Removed'),
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            size="xs"
            loading={ removeLoading }
            leftAddons={ <TrashS /> }
            onClick={ () => confirm('Are you sure?') && removeFetch({ identificator }) }
        />
    );
};

const Resend = ({ identificator }: { identificator: string }) => {
    const { emails } = useApi();
    const { resendFetch, resendLoading } = useApiMethod({
        api: emails.resend,
        successCallback: () => showSuccess('Resended'),
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            size="xs"
            loading={ resendLoading }
            leftAddons={ <UpdateS /> }
            onClick={ () => confirm('Are you sure?') && resendFetch(identificator) }
        />
    );
};

const Resync = ({ identificator }: { identificator: string }) => {
    const { emails } = useApi();
    const { resyncFetch, resyncLoading } = useApiMethod({
        api: emails.resync,
        successCallback: () => showSuccess('Sync successfully'),
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            size="xs"
            loading={ resyncLoading }
            leftAddons={ <SearchS /> }
            onClick={ () => resyncFetch(identificator) }
        />
    );
};

export const EmailTableButtons = ({ email }: { email: EmailRO }) => (
    <Flex gap="sm">
        <OpenEmail email={ email } />

        { email.status !== EmailStatusEnum.INBOX && email.status !== EmailStatusEnum.BOUNCED && (
            <Resend identificator={ email.identificator } />
        ) }

        { email.profile?.title ? (
            <React.Fragment>
                <OpenProfile profile={ email.profile } />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <OpenThread
                    email={ email.status === EmailStatusEnum.INBOX ? email.from : email.to }
                />
                <Resync identificator={ email.identificator } />
            </React.Fragment>
        ) }

        <Remove identificator={ email.identificator } />
    </Flex>
);
