import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    convertToOptions, FiltersForm, FlexColumns, Loader, Select,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { TranslationStatusEnum } from '@escapenavigator/types/dist/translation/enum/translation-status.enum';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikValues } from 'formik';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    submitCallback: (values: FormikValues) => void;
    initialValues?: Record<string, unknown>;
    close: () => void;
};

const defaultValues = {
    locales: [],
};

export const FiltersTransactionModal: React.FC<Props> = ({
    submitCallback,
    close,
    initialValues = {},
}) => {
    const { t } = useTranslation();
    const { translationsApi } = useApi();
    const { current } = useCurrentUser();

    const { getAllNamespacesData, getAllNamespacesFetch } = useApiMethod({
        api: translationsApi.getAllNamespaces,
    });

    useEffect(() => {
        getAllNamespacesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getAllNamespacesData) return <Loader />;

    return (
        <FiltersForm
            submitCallback={ submitCallback }
            initialValues={ initialValues }
            close={ close }
            defaultValues={ defaultValues }
            t={ t }
        >
            { ({ values, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        dataTestId="namespaceId"
                        selected={ values.namespaceId }
                        label="Namespace"
                        onChange={ ({ selected }) => setFieldValue('namespaceId', selected?.key) }
                        options={ convertToOptions(getAllNamespacesData) }
                        block={ true }
                    />

                    <Select
                        dataTestId="status"
                        selected={ values.status }
                        label="Status"
                        onChange={ ({ selected }) => setFieldValue('status', selected?.key) }
                        options={ enumToOptions(TranslationStatusEnum) }
                        block={ true }
                    />

                    <Select
                        dataTestId="locales"
                        selected={ values.locales }
                        label="Locale"
                        multiple={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'locales',
                                selectedMultiple?.map((s) => s.key),
                            ) }
                        options={ languageOptions(
                            current.totalAccess ? Object.values(Languages) : current.languages,
                        ) }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </FiltersForm>
    );
};
