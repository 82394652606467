import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FlexColumns, Input, Select, showError, showSuccess,
} from '@alphakits/ui';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { TestEmailDto } from '@escapenavigator/types/dist/profile/email/test-email.dto';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
};

export const TestSendingModal: React.FC<Props> = ({ close }) => {
    const { t } = useTranslation();
    const { profiles } = useApi();

    const { testEmailFetch, testEmailLoading, testEmailError } = useApiMethod({
        api: profiles.testEmail,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => {
            showSuccess('Sending created');
            close();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ testEmailLoading }
            save={ (values) => testEmailFetch(values) }
            loading={ testEmailLoading }
            softError={ testEmailError?.message }
            validate={ validateByDto(t) }
            title="Create sending"
            close={ close }
            initialValues={ serializeRecord(TestEmailDto, {}) }
        >
            { ({
                values, setFieldValue, touched, errors, handleChange,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label="Email"
                        value={ values.title }
                        onChange={ handleChange('email') }
                        error={ touched.email && errors.email }
                        required={ true }
                        block={ true }
                    />

                    <Select
                        dataTestId="template"
                        selected={ values.country }
                        label="Language"
                        onChange={ ({ selected }) => setFieldValue('country', selected?.key) }
                        options={ enumToOptions(CountriesEnum) }
                        block={ true }
                    />

                    <Select
                        dataTestId="template"
                        selected={ values.emailType }
                        label="Template"
                        onChange={ ({ selected }) => setFieldValue('emailType', selected?.key) }
                        options={ ['email1', 'email2', 'email3'].map((email) => ({
                            key: email,
                            content: email,
                        })) }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
