/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export interface TabPanelProps {
    name: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, name }) => (
    <React.Fragment>{ children }</React.Fragment>
);
