import React from 'react';
import {
    FlexColumns, Input, Textarea, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateProfileContactDto } from '@escapenavigator/types/dist/profile/contacts/create-profile-contact.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    profileId: number;
};

export const OrganizationContact = ({
    close, t, profileId, cb,
}: Props) => {
    const { profiles } = useApi();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: profiles.createContact,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating }
            save={ save }
            softError={ softError }
            validate={ validateByDto(t) }
            title="New contact"
            close={ close }
            initialValues={ serializeRecord(CreateProfileContactDto, { profileId }) }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label="Name and role"
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            block={ true }
                        />

                        <Input
                            label="Email"
                            value={ values.email }
                            onChange={ handleChange('email') }
                            block={ true }
                        />

                        <Input
                            label="Phone"
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            block={ true }
                        />

                        <Input
                            label="Facebook"
                            value={ values.facebook }
                            onChange={ handleChange('facebook') }
                            block={ true }
                        />

                        <Input
                            label="LinkedIn"
                            value={ values.linkedin }
                            onChange={ handleChange('linkedin') }
                            block={ true }
                        />

                        <Input
                            label="Xing"
                            value={ values.xing }
                            onChange={ handleChange('xing') }
                            block={ true }
                        />

                        <Textarea
                            dataTestId="comment"
                            label="Comment"
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            block={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
