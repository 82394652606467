import React, { useContext, useEffect } from 'react';
import { ModalContext, showError, Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileCrmRO } from '@escapenavigator/types/dist/profile/profile-crm/profile-crm.ro';
import { ProfileCrmModal } from 'src/modals/profile-crm';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const ProfileCrmsPage: React.FC<DefaultPageProps> = () => {
    const { profiles } = useApi();

    const { getAllCrmData, getAllCrmLoading, getAllCrmFetch } = useApiMethod({
        api: profiles.getAllCrm,
        errorCallback: () => showError('Error'),
    });

    useEffect(() => {
        getAllCrmFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { openModal } = useContext(ModalContext);

    const onRowClick = (record?: ProfileCrmRO) => {
        openModal(ProfileCrmModal)({ record, cb: () => getAllCrmFetch(undefined) });
    };

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ columns }
                onCreateClick={ () => onRowClick() }
                onRowClick={ (data) => onRowClick(data) }
                loading={ getAllCrmLoading }
                language="en"
                records={ (getAllCrmData || []).sort((a, b) => b.profiles - a.profiles) }
            />
        </React.Fragment>
    );
};
