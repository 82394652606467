import React from 'react';
import { Amount, Image, Status } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { AggregatorOrderRO } from '@escapenavigator/types/dist/openapi/now-escape/now-escape-order.ro';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils';

export const ordersColumns = (): TableColumns<AggregatorOrderRO> => [
    {
        header: 'Date',
        accessor: 'utcDate',
        row: {
            title: ({ row: { questroom, utcDate } }) => {
                if (!questroom) return 'Deleted escape room';

                return convertUTCDateToZonedDate({
                    date: utcDate,
                    timeZone: questroom.location.timeZone,
                });
            },
            subtitle: ({ row }) => row.questroom?.title,
            icon: ({ row }) => <Image view="ellipse" src={ row.questroom?.photo } />,
        },
    },
    {
        header: 'Code',
        accessor: 'code',
        row: {
            title: ({ row }) => row.code,
            subtitle: ({ row }) => `ID ${row.otherId}`,
        },
    },

    {
        header: 'Totla',
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    weight="bold"
                    color="primary"
                    currency="EUR"
                    value={ row.total }
                />
            ),
            subtitle: ({ row }) =>
                (
                    <React.Fragment>
                        Paid{ ' ' }
                        <Amount
                            type="decimal"
                            currencyOpacity={ false }
                            view="caps"
                            currency="EUR"
                            value={ row.payed }
                        />
                    </React.Fragment>
                ) as any,
        },
    },
    {
        header: '',
        accessor: 'slotId',
        align: 'right',
        row: {
            title: ({ row }) => (
                <Status view="contrast" color={ row.slotId ? 'green' : 'grey' }>
                    { row.slotId ? 'Accepted' : 'Canceled' }
                </Status>
            ),
        },
    },
];
