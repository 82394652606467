import React, { useEffect } from 'react';
import { Flex, FlexColumns, Tag } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { useApi } from 'src/providers/api/context';

type Props = {
    country?: CountriesEnum;
    setCountry: (country?: CountriesEnum) => void;
    city?: string;
    setCity: (country?: string) => void;
};

export const CountriesFilter: React.FC<Props> = ({
    setCountry, country, city, setCity,
}) => {
    const { cities } = useApi();

    const { getPopularForAdminData, getPopularForAdminFetch } = useApiMethod({
        api: cities.getPopularForAdmin,
    });

    useEffect(() => {
        getPopularForAdminFetch(country ? { country } : {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    return (
        <FlexColumns columns={ 1 }>
            <Flex justify="start" gap="xs">
                <Tag
                    onClick={ () => setCountry(undefined) }
                    text="All "
                    view={ country ? 'primary' : 'primary-inverted' }
                />
                { Object.values(CountriesEnum).map((c) => (
                    <Tag
                        onClick={ () => setCountry(country === c ? undefined : c) }
                        text={ c }
                        view={ country === c ? 'primary-inverted' : 'primary' }
                    />
                )) }
            </Flex>

            <Flex justify="start" gap="xs">
                { getPopularForAdminData?.map((c) => (
                    <Tag
                        onClick={ () => setCity(city === c.slug ? undefined : c.slug) }
                        text={ c.title }
                        view={ city === c.slug ? 'primary-inverted' : 'primary' }
                    />
                )) }
            </Flex>
        </FlexColumns>
    );
};
