import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
// import { FiltersRequestModal } from 'src/modals/filters/request';
import { RegistrationRequestModal } from 'src/modals/registration-request';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const RegistrationsPage: React.FC<DefaultPageProps> = () => {
    const { registrationRequests } = useApi();

    const { openModal } = useContext(ModalContext);

    // const openFilters = openModal(FiltersRequestModal);
    const openRequest = openModal(RegistrationRequestModal);

    return (
        <React.Fragment>
            <Table.TableComponent
                tagsBuilder={ mapper() }
                onRowClick={ ({ id }, refetch) => openRequest({ recordId: id, cb: refetch }) }
                // onFiltersClick={ openFilters }
                columns={ columns() }
                searchPlaceholder="Find by id, phone or email"
                language="en"
                promise={ registrationRequests.query }
            />
        </React.Fragment>
    );
};
