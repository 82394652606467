import React, { useState } from 'react';
import { Button, ImageGallery } from '@alphakits/ui/dist';

type Props = {
    src: string;
};

export const Gallery = ({ src }: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Button view="ghost" onClick={ () => setOpen(true) }>
                <img alt="" style={ { width: '100%' } } src={ src } />
            </Button>

            <ImageGallery
                initialSlide={ 0 }
                open={ open }
                photos={ [
                    {
                        src,
                    },
                ] }
                onClose={ () => setOpen(false) }
            />
        </React.Fragment>
    );
};
