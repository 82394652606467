import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { ArticleModal } from 'src/modals/article';
import { ArticlesFilterModal } from 'src/modals/filters/articles';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const Articles: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const openRequest = openModal(ArticleModal);

    const { articles } = useApi();

    return (
        <React.Fragment>
            <Table.TableComponent
                tagsBuilder={ mapper() }
                filtersButtonLabel="Filters"
                onFiltersClick={ (props) =>
                    openModal(
                        ArticlesFilterModal,
                        's',
                    )({
                        submitCallback: props.submitCallback,
                        initialValues: props.initialValues.where,
                    }) }
                onRowClick={ ({ id }) => openRequest({ recordId: +id }) }
                onCreateClick={ () => openRequest() }
                columns={ columns }
                searchPlaceholder="Serach by title"
                language="en"
                promise={ articles.query }
            />
        </React.Fragment>
    );
};
