import React from 'react';
import { TabPanel, Tabs } from 'src/components/tabs';

import { NavigatorCertificates } from './navigator';
import { Templates } from './templates';

export const CertificatesPage: React.FC = () => (
    <Tabs view="header" defaultActive="Sales">
        <TabPanel name="Sales">
            <NavigatorCertificates />
        </TabPanel>

        <TabPanel name="Templates">
            <Templates />
        </TabPanel>
    </Tabs>
);
