import React, { useEffect } from 'react';
import {
    Divider, FlexColumns, Loader, Padding, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { EmailCell } from 'src/components/email-cell';
import { ModalContainer } from 'src/components/modal-container';
import { useApi } from 'src/providers/api/context';

import { EmailSendForm } from '../email-send-form';

type Props = {
    email: string;
};

export const EmailThread: React.FC<Props> = ({ email }) => {
    const { emails } = useApi();

    const { queryData, queryFetch, queryLoading } = useApiMethod({
        api: emails.query,
    });

    useEffect(() => {
        queryFetch({
            page: 1,
            limit: 100,
            where: { email },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    if (queryLoading || !queryData) return <Loader />;

    return (
        <ModalContainer>
            <Padding padding="32px 0 0 0" />

            <FlexColumns columns={ 1 } gr={ 8 }>
                <Typography.Title tag="div" weight="bold" view="small">
                    { email }
                </Typography.Title>

                <Padding padding="16px 0 0 0" />

                { queryData.items
                    ?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
                    .map((e) => (
                        <EmailCell email={ e } />
                    )) }

                <Divider size="m" />

                <EmailSendForm
                    cb={ () => queryFetch({ where: { email }, page: 1, limit: 100 }) }
                    email={ email }
                />
            </FlexColumns>
        </ModalContainer>
    );
};
