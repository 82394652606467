import React from 'react';
import { Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { ordersColumns } from './columns';

export const Sales: React.FC<DefaultPageProps> = () => {
    const { orders } = useApi();

    const { queryAgregaotrOrderFetch } = useApiMethod({
        api: orders.queryAgregaotrOrder,
    });

    return (
        <Table.TableComponent
            columns={ ordersColumns() }
            searchPlaceholder="Serach"
            language="en"
            promise={ queryAgregaotrOrderFetch }
        />
    );
};
