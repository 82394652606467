import React from 'react';
import { Tag } from '@alphakits/ui';

type Props = {
    row: string;
    value: string;
    removeParam: (taggName: string) => void;
};

export const Country: React.FC<Props> = ({ value, row, removeParam }) => (
    <Tag text={ value } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />
);
