import React from 'react';
import {
    FlexColumns, Textarea, Typography, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { TranslationRO } from '@escapenavigator/types/dist/translation/translation.ro';
import { UpdateTranslationLocaleDto } from '@escapenavigator/types/dist/translation/update-translation-locale.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { Gallery } from 'src/components/gallery';
import { useApi } from 'src/providers/api/context';

type Props = {
    locale: TranslationRO['translations'][0];
    translation: TranslationRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const TranslationLocaleModal = ({
    close, t, cb, locale, translation,
}: Props) => {
    const { translationsApi } = useApi();

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId: locale.id,
        saveRequest: translationsApi.updateLocale,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Update translation"
            close={ close }
            initialValues={ serializeRecord(UpdateTranslationLocaleDto, locale) }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <FlexColumns columns={ 1 }>
                    <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                        <Typography.Text view="title" weight="bold">
                            Оригинальный текст
                        </Typography.Text>

                        <Typography.Text view="primary-medium" color="primary" weight="medium">
                            { translation.translations.find((t) => t.locale === Languages.RU)?.text }
                        </Typography.Text>
                    </FlexColumns>

                    { (translation.photo || translation.context) && (
                        <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                            <Typography.Text view="title" weight="bold">
                                Контекст
                            </Typography.Text>

                            { translation.photo && <Gallery src={ translation.photo } /> }

                            <Typography.Text view="primary-medium" color="primary" weight="medium">
                                { translation.context }
                            </Typography.Text>
                        </FlexColumns>
                    ) }

                    <Textarea
                        dataTestId="ru"
                        label={ `Translation for ${locale.locale}` }
                        value={ values.text }
                        maxLength={ 1500 }
                        counter={ true }
                        onChange={ handleChange('text') }
                        error={ touched.text && errors.text }
                        required={ true }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
