import React, { useContext, useEffect, useState } from 'react';
import { InfoMarkS } from '@alphakits/icons';
import {
    Button,
    Flex,
    ModalContext,
    Status,
    StatusProps,
    Tooltip,
    Typography,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { AdminProfileRO } from '@escapenavigator/types/dist/profile/admin-profile.ro';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { ProfileTagEnum } from '@escapenavigator/types/dist/profile/enum/profile-tag.enum';
import { QueryAdminProfileDto } from '@escapenavigator/types/dist/profile/query-admin-profile.dto';
import { differenceInDays } from 'date-fns';
import { OrganizationInfoModal } from 'src/modals/organization-info/main';
import { useApi } from 'src/providers/api/context';

import styles from './index.module.css';

const getK = (step: ProfileStatusEnum) => {
    switch (step) {
        case ProfileStatusEnum.SENT_EMAIL:
            return 4;

        default:
            return 2;
    }
};

const getColor = (profile: AdminProfileRO): StatusProps['color'] => {
    if (profile.tags.includes(ProfileTagEnum.WAIT)) {
        return 'purple';
    }

    if (profile.tags.includes(ProfileTagEnum.PRESENTATION)) {
        return 'teal';
    }

    const diff = differenceInDays(new Date(), new Date(profile.updateDate));

    const k = getK(profile.status);

    if (diff < k) return 'blue';
    if (diff < k * 2) return 'orange';

    return 'grey';
};

type Props = {
    color:
    | 'link'
    | 'tertiary'
    | 'disabled'
    | 'accent'
    | 'primary'
    | 'attention'
    | 'positive'
    | 'secondary'
    | 'tertiary-inverted'
    | 'primary-inverted'
    | 'secondary-inverted'
    | 'negative';
    title: string;
    where: QueryAdminProfileDto;
};

export const Row: React.FC<Props> = ({ where, color, title }) => {
    const { profiles: profileApi } = useApi();
    const { openModal } = useContext(ModalContext);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [profiles, setProfiles] = useState<AdminProfileRO[]>([]);

    const { queryFetch, queryLoading } = useApiMethod({
        api: profileApi.query,
    });

    const fetch = (page: number) => {
        setPage(page);
        setHasMore(true);

        const initial = page === 1 ? [] : profiles;

        queryFetch({
            page,
            limit: 12,
            sort: 'updateDate',
            order: 'DESC',
            where,
        }).then(({ data }) => {
            setProfiles([...data.items, ...initial]);
            setHasMore(data.items.length === 12);
            setTotal(data.meta.totalItems);
        });
    };

    useEffect(() => {
        fetch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [where]);

    return (
        <div style={ { opacity: queryLoading ? 0.3 : 1 } }>
            <Flex gap="md" justify="start" className={ styles.row }>
                <Typography.Text className={ styles.title } view="title" weight="bold" color={ color }>
                    { title } ({ total })
                </Typography.Text>

                { profiles.map((profile) => (
                    <Button
                        view="ghost"
                        style={ { opacity: profile.tags.includes(ProfileTagEnum.REJECT) ? 0.4 : 1 } }
                        onClick={ () =>
                            openModal(OrganizationInfoModal, 'm')({ profileId: profile.id }) }
                    >
                        <Status color={ getColor(profile) } view="contrast" size="m">
                            (#{ profile.lastEmailSendId }) { profile.title } ({ profile.country })
                            <Tooltip
                                trigger="hover"
                                position="bottom"
                                content={ (
                                    <div style={ { width: 220, overflow: 'auto' } }>
                                        <Flex
                                            justify="start"
                                            gap="md"
                                            direction="column"
                                            align="start"
                                        >
                                            <Typography.Text view="caps" color="primary">
                                                { profile.status }
                                            </Typography.Text>
                                            <Typography.Text view="caps" color="primary">
                                                Questrooms count { profile.questroomsCount }
                                            </Typography.Text>
                                            <Typography.Text view="caps" color="primary">
                                                Programm { profile.partnerProgram } / { profile.crm }
                                            </Typography.Text>
                                            <Typography.Text view="caps" color="primary">
                                                Subscription { profile.subscriptionType }
                                            </Typography.Text>
                                            <Typography.Text view="caps" color="primary">
                                                Subscription status { profile.subscriptionStatus }
                                            </Typography.Text>
                                        </Flex>
                                    </div>
                                ) }
                            >
                                <InfoMarkS />
                            </Tooltip>
                        </Status>
                    </Button>
                )) }

                { hasMore && !queryLoading && (
                    <Button view="outlined" onClick={ () => fetch(page + 1) }>
                        Load more
                    </Button>
                ) }
            </Flex>
        </div>
    );
};
