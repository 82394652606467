import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { UpdateReview } from 'src/modals/social-review';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const SocialReviews: React.FC<DefaultPageProps> = () => {
    const { socialReviews } = useApi();
    const { openModal } = useContext(ModalContext);

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ columns }
                onRowClick={ (review, cb) => openModal(UpdateReview)({ review, cb }) }
                searchPlaceholder="Serach"
                language="en"
                promise={ socialReviews.queryAdmin }
            />
        </React.Fragment>
    );
};
